/* eslint-disable @typescript-eslint/no-explicit-any */
/** @jsxImportSource theme-ui */
import React, { useCallback, useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { Flex } from 'theme-ui';

import { PaymentProvidersEnum } from '../../../../@types/enums';
import { useTurnstile } from '../../../../contextProviders/turnstileContext';
import { actionCreators } from '../../../../store/ActionCreators';
import {
  selectBankCardAmount,
  selectGrandTotalAfterDiscountsInCents,
  selectHostedPayments,
  selectSelectedLanguageCulture,
  selectSelectedPaymentProvider,
} from '../../../../store/Selectors';

const AmazonPayV2: React.FC = () => {
  const dispatch = useDispatch();
  const turnstile = useTurnstile();

  const bankCardAmount = useSelector(selectBankCardAmount);
  const selectedLanguageCulture = useSelector(selectSelectedLanguageCulture);
  const hostedPayments = useSelector(selectHostedPayments);
  const selectedPaymentProvider = useSelector(selectSelectedPaymentProvider);
  const [scriptLoaded, setScriptLoaded] = useState(false);
  const grandTotal = useSelector(selectGrandTotalAfterDiscountsInCents);

  const hostedPayment = hostedPayments[PaymentProvidersEnum.AMAZONPAYV2];
  const isAmazonPaySelected =
    selectedPaymentProvider === PaymentProvidersEnum.AMAZONPAYV2;
  const scriptUrl = hostedPayment?.scriptUrl;
  const _window = window as any;

  const showButton = useCallback(() => {
    if (!hostedPayment || !_window.amazon) {
      return;
    }
    _window.amazon.Pay.renderButton('#AmazonPayButtonV2', {
      // set checkout environment
      merchantId: hostedPayment.merchantId,
      publicKeyId: hostedPayment.publicKeyId,
      ledgerCurrency: hostedPayment.ledgerCurrency,
      // customize the buyer experience
      checkoutLanguage: selectedLanguageCulture.replace('-', '_'),
      productType: 'PayOnly',
      placement: 'Checkout',
      buttonColor: 'Gold',
      estimatedOrderAmount: {
        amount: grandTotal,
        currencyCode: hostedPayment.ledgerCurrency,
      },
      // configure Create Checkout Session request
      createCheckoutSessionConfig: {
        payloadJSON: hostedPayment.payload, // string generated in step 2
        signature: hostedPayment.signature, // signature generated in step 3
        algorithm: 'AMZN-PAY-RSASSA-PSS-V2',
        publicKey: hostedPayment.publicKeyId,
      },
    });
  }, [_window.amazon, grandTotal, hostedPayment, selectedLanguageCulture]);

  const getPaymentProviderAsync = useCallback(async () => {
    dispatch(
      actionCreators.initializeAmazonPayV2Payment({
        turnstile,
      })
    );
  }, [dispatch, turnstile]);

  // reacts to bankCardAmount Changing and sets hostedpayment to null
  useEffect(() => {
    if (
      hostedPayment &&
      (bankCardAmount !== hostedPayment.bankCardAmount ||
        grandTotal !== hostedPayment?.grandTotal)
    ) {
      dispatch(
        actionCreators.setHostedPayment(PaymentProvidersEnum.AMAZONPAYV2, null)
      );
    }
  }, [bankCardAmount, dispatch, grandTotal, hostedPayment]);

  // Loads Amazon script
  useEffect(() => {
    if (scriptLoaded || !scriptUrl) {
      return;
    }

    const script = document.createElement('script');
    script.src = scriptUrl;
    document.head.appendChild(script);
    script.onload = function () {
      showButton();
    };
    setScriptLoaded(true);
  }, [
    _window,
    hostedPayment,
    scriptLoaded,
    selectedLanguageCulture,
    scriptUrl,
    showButton,
  ]);

  // Calls GetPaymentProvider
  useEffect(() => {
    if (isAmazonPaySelected && !hostedPayment) {
      getPaymentProviderAsync();
    }
  }, [hostedPayment, getPaymentProviderAsync, isAmazonPaySelected]);

  return (
    <Flex sx={{ justifyContent: 'center' }}>
      <div id='AmazonPayButtonV2' />
    </Flex>
  );
};

export default AmazonPayV2;
