/** @jsxImportSource theme-ui */
import React from 'react';

import classnames from 'classnames';
import { useIntl } from 'react-intl';

import { getNumberOfItemsToSelect } from '../../../services/Helpers';
import { ReactComponent as RadioSelectedSvg } from '../../../svgs/radioSelected.svg';
import globalMessages from '../../widgets/intl';
import ContainedRow from '../layout/ContainedRow';

interface Props {
  allSelected: boolean;
  numberSelected: number;
  numberToSelect: number;
  isTopBar?: boolean;
}

const SelectedHeading: React.FC<Props> = ({
  allSelected,
  numberSelected,
  numberToSelect,
  isTopBar,
}) => {
  const { formatMessage } = useIntl();

  return (
    <ContainedRow innerStyles={{ display: 'flex', justifyContent: 'center' }}>
      <h2
        className={classnames('select-heading', allSelected && 'all-selected')}
        sx={{
          mt: isTopBar ? 0 : 4,
          display: 'flex',
          alignItems: 'center',
          minHeight: '30px',
          fontWeight: 'inherit',
        }}
      >
        {allSelected && <RadioSelectedSvg className='all-selected-check' />}
        <div>
          {getNumberOfItemsToSelect(
            numberToSelect,
            numberSelected,
            formatMessage(globalMessages.ofLabel),
            formatMessage(globalMessages.selectedLabel)
          )}
        </div>
      </h2>
    </ContainedRow>
  );
};

export default SelectedHeading;
