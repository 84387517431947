import { defineMessages } from 'react-intl';

export default defineMessages({
  gratuityTitle: {
    id: 'gratuity.gratuity-title-text',
    defaultMessage: 'Add gratuity',
  },
  gratuityRichText: {
    id: 'gratuity.gratuity-richtext',
    defaultMessage:
      '<p>Please select the amount you would like to add as gratuity.</p>',
  },
});
