import React, { FC, memo, useState } from 'react';

import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import GiftCardPayment from './giftcardcomponents/GiftCardPayment';
import messages from './intl';

import { actionCreators } from '../../../store/ActionCreators';
import {
  selectAppliedGiftCards,
  selectDazzlerLocale,
  selectIsCustomerReadyForPayment,
  selectIsPaymentPageValidated,
  selectShowGiftCardForm,
} from '../../../store/Selectors';
import ContainedRow from '../../common/layout/ContainedRow';
import { resolveLocalisedStringOrDefault } from '../helpers';
import globalMessages from '../intl';
import BorderedCollapse from '../shared/borderedcollapse/BorderedCollapse';
import { WidgetData } from '../types';

type Props = {
  widget: WidgetData<'TicketingCMSJourneyGiftCardPaymentWidget'>;
};

export const GiftCardPaymentForm: FC<Props> = ({ widget }) => {
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();
  const locale = useSelector(selectDazzlerLocale);

  const appliedGiftCards = useSelector(selectAppliedGiftCards);
  const [expandGiftCardForm, setExpandGiftCardForm] = useState(
    appliedGiftCards?.length > 0
  );
  const setPaymentFormVisibility = (nextShowPaymentForm: boolean) => {
    dispatch(actionCreators.setShowPaymentForm(nextShowPaymentForm));
  };

  const isPageValidated = useSelector(selectIsPaymentPageValidated);
  const showGiftCardForm = useSelector(selectShowGiftCardForm);
  const isCustomerReadyForPayment = useSelector(
    selectIsCustomerReadyForPayment
  );

  const isReadyForPayment = isPageValidated && isCustomerReadyForPayment;

  const handleValidatePage = () => {
    dispatch(actionCreators.setIsPaymentPageValidated(true));
  };

  if (!isReadyForPayment || !showGiftCardForm) return;

  return (
    <ContainedRow classNameWrapper='giftcard-wrapper'>
      <BorderedCollapse
        closeButtonText={formatMessage(globalMessages.closeButtonText)}
        heading={resolveLocalisedStringOrDefault(
          formatMessage(messages.giftCardHeading),
          locale,
          widget.shape?.giftCardHeading
        )}
        setShow={setExpandGiftCardForm}
        show={expandGiftCardForm}
      >
        <GiftCardPayment
          setPaymentFormVisibility={setPaymentFormVisibility}
          isPageValidated={isPageValidated}
          handleValidatePage={handleValidatePage}
          isShown={expandGiftCardForm}
          giftCardProviderAcceptsPin={widget.shape?.giftCardProviderAcceptsPin}
          widget={widget}
        />
      </BorderedCollapse>
    </ContainedRow>
  );
};

export default memo(GiftCardPaymentForm);
