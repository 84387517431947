/** @jsxImportSource theme-ui */
import React, { useState, useEffect } from 'react';

import classnames from 'classnames';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import CartSummaryBookingFee from './CartSummaryBookingFee';
import CartSummaryDeliveryItems from './CartSummaryDeliveryItems';
import CartSummaryDeliveryOptions from './CartSummaryDeliveryOptions';
import CartSummaryDonations from './CartSummaryDonations';
import CartSummaryFaBConcessions from './CartSummaryFaBConcessions';
import CartSummaryGCConcessions from './CartSummaryGCConcessions';
import CartSummaryGiftCards from './CartSummaryGiftCards';
import CartSummaryGratuity from './CartSummaryGratuity';
import CartSummaryLoyaltyConcessions from './CartSummaryLoyaltyConcessions';
import CartSummaryLoyaltyRedemption from './CartSummaryLoyaltyRedemption';
import CartSummaryPhysicalGiftCards from './CartSummaryPhysicalGiftCards';
import CartSummarySeasonPass from './CartSummarySeasonPass';
import CartSummarySeats from './CartSummarySeats';
import CartSummarySubtotal from './CartSummarySubtotal';
import CartSummaryTax from './CartSummaryTax';
import CartSummaryTickets from './CartSummaryTickets';
import CartSummaryVouchers from './CartSummaryVouchers';

import { JOURNEY_TYPES } from '../../../constants';
import {
  selectBookingFee,
  selectBookingFeeTax,
  selectCartSummary,
  selectConfirmationData,
  selectContent,
  selectDonation,
  selectGrandTotalBeforeDiscountInCents,
  selectCalculatedGratuityInCents,
  selectHideTax,
  selectProductsTax,
  selectRequiresDeliveryOptions,
  selectSelectedFaBConcessions,
  selectSelectedSeats,
  selectTicketTypes,
  selectGratuityInCents,
  selectJourneyTypeConfig,
  selectTotalGiftCardsSelected,
  selectIsDeliveryOptionBasedOnSeatingArea,
  selectSelectedSeasonPassItem,
} from '../../../store/Selectors';

interface Props {
  hideTotal?: boolean;
}

const CartSummaryItems: React.FC<Props> = ({ hideTotal }) => {
  const { pathname } = useLocation();

  const bookingFee = useSelector(selectBookingFee);
  const cartSummary = useSelector(selectCartSummary);
  const confirmationData = useSelector(selectConfirmationData);
  const content = useSelector(selectContent);
  const donation = useSelector(selectDonation);
  const gratuityInCentsConfirmation = useSelector(selectGratuityInCents);
  const journeyTypeConfig = useSelector(selectJourneyTypeConfig);
  const selectedFabConcessions = useSelector(selectSelectedFaBConcessions);
  const selectedGiftcardsCount = useSelector(selectTotalGiftCardsSelected);
  const selectedSeats = useSelector(selectSelectedSeats);
  const bookingFeeTax = useSelector(selectBookingFeeTax);
  const ticketTypes = useSelector(selectTicketTypes);
  const grandTotalBeforeDiscountInCents = useSelector(
    selectGrandTotalBeforeDiscountInCents
  );
  const productsTaxFromState = useSelector(selectProductsTax);
  const gratuityInCentsSelectorValue = useSelector(
    selectCalculatedGratuityInCents
  );
  const hideTax = useSelector(selectHideTax);
  const requiresDeliveryOptions = useSelector(selectRequiresDeliveryOptions);
  const isDeliveryOptionBasedOnSeatingArea = useSelector(
    selectIsDeliveryOptionBasedOnSeatingArea
  );
  const selectedSeasonPassItem = useSelector(selectSelectedSeasonPassItem);

  const [cartHasItems, setCartHasItems] = useState(false);

  const isConfirmation = pathname.includes('/confirmation/');
  const isRefund = pathname.startsWith('/refund/');

  const grandTotal =
    isConfirmation && confirmationData
      ? confirmationData.totalPrice
      : grandTotalBeforeDiscountInCents;

  const gratuityInCents =
    isConfirmation && confirmationData
      ? gratuityInCentsConfirmation
      : gratuityInCentsSelectorValue;

  const productsTax =
    isConfirmation && confirmationData
      ? confirmationData.productsTax
      : productsTaxFromState;

  const isSeasonPassJourney =
    journeyTypeConfig.type === JOURNEY_TYPES.SEASON_PASS;

  useEffect(() => {
    const hasFaBConcessions = !!selectedFabConcessions.list?.find(
      (x) => x.quantity > 0
    );
    const hasGCConcessions = !!selectedGiftcardsCount;
    const hasDonation = donation > 0;
    const hasGratuity = gratuityInCents > 0;
    const hasTickets = !!ticketTypes?.ticketTypeModels.find(
      (t) => t.quantity > 0
    );
    const hasSeasonPassTickets = isSeasonPassJourney
      ? selectedSeasonPassItem?.ticketTypes.some(
          (ticket) => ticket.quantity > 0
        )
      : false;

    const hasItems =
      hasGCConcessions ||
      hasFaBConcessions ||
      hasDonation ||
      hasGratuity ||
      hasTickets ||
      hasSeasonPassTickets;

    hasItems !== cartHasItems && setCartHasItems(hasItems);
  }, [
    selectedFabConcessions,
    donation,
    gratuityInCents,
    ticketTypes,
    cartHasItems,
    selectedGiftcardsCount,
    selectedSeasonPassItem,
    isSeasonPassJourney,
  ]);

  const totalExcludingBookingFee = grandTotal - gratuityInCents - bookingFee;
  const totalExcludingTax =
    grandTotal - gratuityInCents - (productsTax + bookingFeeTax);
  const subtotal = hideTax ? totalExcludingBookingFee : totalExcludingTax;

  if (!content?.cartSummary || !cartSummary) return null;

  const includeExcludeText =
    isConfirmation || isRefund
      ? content.cartSummary.pricesLabelIncludeText
      : content.cartSummary.pricesLabelExcludeText;

  const hasFabConcessions = selectedFabConcessions?.list?.length > 0;
  const isPathValid = [
    '/kiosk',
    '/payment',
    '/confirmation',
    '/concessions/payment',
    '/concessions/confirmation',
  ].some((path) => pathname.startsWith(path));

  const shouldShowFaBDeliveryOption =
    (requiresDeliveryOptions || isDeliveryOptionBasedOnSeatingArea) &&
    selectedSeats &&
    hasFabConcessions &&
    isPathValid;

  const shouldShowSubtotal =
    gratuityInCents > 0 ||
    (hideTax && bookingFee > 0) ||
    (!hideTax && (productsTax > 0 || bookingFeeTax > 0));

  return (
    <>
      {!hideTax && cartHasItems && (
        <div className='cart-summary-row prices'>
          <div className='small' sx={{ textAlign: 'right' }}>
            {content.cartSummary.pricesLabel}{' '}
            <span sx={{ fontWeight: 'bold' }}>{includeExcludeText}</span>
          </div>
        </div>
      )}

      {!isSeasonPassJourney && (
        <div
          className={classnames(
            'cart-items',
            cartHasItems && hideTax && 'hide-tax'
          )}
          sx={{
            '&.hide-tax': {
              borderTop: 'sidebarSeparator',
            },
            '.cart-summary-row': {
              borderTop: 'sidebarSeparator',
            },
          }}
        >
          <CartSummaryTickets hideTax={hideTax} />
          <CartSummaryDonations />
          {journeyTypeConfig.hasKioskStep ? (
            <CartSummaryFaBConcessions hideTax={hideTax} pathname={pathname} />
          ) : (
            <CartSummaryLoyaltyConcessions hideTax={hideTax} />
          )}

          <CartSummaryGCConcessions hideTax={hideTax} pathname={pathname} />

          <CartSummaryDeliveryItems hideTax={hideTax} />

          {journeyTypeConfig.type !== JOURNEY_TYPES.CONCESSIONS_ONLY && (
            <CartSummarySeats hideTotal={hideTotal} />
          )}

          <CartSummaryLoyaltyRedemption />
        </div>
      )}

      {shouldShowFaBDeliveryOption && <CartSummaryDeliveryOptions />}

      {isSeasonPassJourney && (
        <>
          <CartSummarySeasonPass />
          <CartSummaryLoyaltyRedemption />
          <CartSummaryDonations />
        </>
      )}

      <CartSummaryVouchers
        grandTotal={grandTotal}
        isConfirmation={isConfirmation}
      />
      <CartSummaryGiftCards isConfirmation={isConfirmation} />

      {isConfirmation && journeyTypeConfig.usesPhysicalGiftCardsConfigs && (
        <CartSummaryPhysicalGiftCards />
      )}

      {!hideTax && (
        <CartSummaryBookingFee
          bookingFee={bookingFee}
          bookingFeeTax={bookingFeeTax}
          hideTax={hideTax}
          isInsideSubtotal={false}
        />
      )}

      {shouldShowSubtotal && (
        <CartSummarySubtotal
          hideTax={hideTax}
          pathname={pathname}
          subtotal={subtotal}
        >
          {hideTax && (
            <CartSummaryBookingFee
              bookingFee={bookingFee}
              bookingFeeTax={bookingFeeTax}
              hideTax={hideTax}
              isInsideSubtotal={true}
            />
          )}
          {!hideTax && (
            <CartSummaryTax
              bookingFeeTax={bookingFeeTax}
              hideTax={hideTax}
              productsTax={productsTax}
            />
          )}
          <CartSummaryGratuity gratuityInCents={gratuityInCents} />
        </CartSummarySubtotal>
      )}
    </>
  );
};

export default CartSummaryItems;
