/** @jsxImportSource theme-ui */
import React, { useState, useRef } from 'react';

import classnames from 'classnames';
import { Button, Overlay, Tooltip } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Flex, Grid } from 'theme-ui';
import { isNumeric } from 'validator';

import { DonationContent } from '../../../@types/modelTypes';
import {
  displayPrice,
  getAmountButtonTitle,
  getCustomAmountSelectedTitle,
  getGratuityInCents,
} from '../../../services/Helpers';
import {
  selectCurrencyConfig,
  selectCurrencySymbol,
  selectSelectedFaBConcessions,
  selectSelectedDonation,
  selectSelectedGratuity,
  selectTicketTypes,
} from '../../../store/Selectors';
import { ReactComponent as RadioSelectedSvg } from '../../../svgs/radioSelected.svg';

interface Props {
  isPercentage?: boolean;
  maxAmount: number;
  setAmount: (
    isCustomAmount: boolean,
    amount: number,
    percentage: number
  ) => void;
  values: readonly (number | null)[] | null | undefined;
  variant?: 'donation' | 'gratuity';
  amountContent: DonationContent;
}

const AmountSelector: React.FC<Props> = ({
  isPercentage,
  maxAmount,
  setAmount,
  values,
  variant,
  amountContent,
}) => {
  const dispatch = useDispatch();

  const selectedConcessions = useSelector(selectSelectedFaBConcessions);
  const selectedDonation = useSelector(selectSelectedDonation);
  const selectedGratuity = useSelector(selectSelectedGratuity);
  const ticketTypes = useSelector(selectTicketTypes);

  const [amountSelected, setAmountSelected] = useState<number | null>(null);
  const [customAmountSelected, setCustomAmountSelected] = useState(
    (variant === 'donation' && selectedDonation.isCustomAmount) ||
      (variant === 'gratuity' && selectedGratuity.isCustomAmount)
  );
  const [customAmountValue, setCustomAmountValue] = useState('');
  const [showCustomAmountTooltip, setShowCustomAmountTooltip] = useState(false);
  const currencyConfig = useSelector(selectCurrencyConfig);
  const currencySymbol = useSelector(selectCurrencySymbol);
  const customAmountRef = useRef(null);

  const parseNumber = (value: string) => {
    const parsedValue = Number.parseFloat(value);
    const num = parsedValue > 0 ? parsedValue : 0;
    return num;
  };

  const handleCustomAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const nextCustomAmountValue = e.target.value;
    if (nextCustomAmountValue === '' || isNumeric(nextCustomAmountValue)) {
      if (parseNumber(nextCustomAmountValue) > maxAmount) {
        setShowCustomAmountTooltip(true);
        setTimeout(() => {
          setShowCustomAmountTooltip(false);
        }, 4000);
        return;
      } else {
        setShowCustomAmountTooltip(false);
      }
      setCustomAmountValue(nextCustomAmountValue);
      dispatch(setAmount(true, parseNumber(nextCustomAmountValue) * 100, 0));
    }
  };

  const handleCustomAmountSelect = () => {
    setAmountSelected(null);
    const toggleButtonOn = customAmountSelected === false;
    setCustomAmountSelected(!!toggleButtonOn);
    dispatch(
      setAmount(
        true,
        toggleButtonOn ? parseNumber(customAmountValue) * 100 : 0,
        0
      )
    );
  };

  const handleAmountSelect = (amount: number | null) => {
    if (amount === null) {
      return;
    }
    setCustomAmountSelected(false);
    const toggleButtonOn = amountSelected !== amount;
    setAmountSelected(toggleButtonOn ? amount : null);
    setShowCustomAmountTooltip(false);
    if (isPercentage) {
      dispatch(setAmount(false, 0, toggleButtonOn ? amount : 0));
    } else {
      dispatch(setAmount(false, toggleButtonOn ? amount * 100 : 0, 0));
    }
  };

  const customLabel = amountContent.amountSelectorCustomAmountLabel;
  const selectedText = amountContent.amountSelectorSelectedText;
  const customAmountTitle = customAmountSelected
    ? getCustomAmountSelectedTitle(
        currencySymbol,
        customLabel,
        customAmountValue,
        selectedText
      )
    : customLabel;

  const customAmountInputValue = () => {
    if (variant === 'donation') {
      if (customAmountSelected && selectedDonation.amount > 0) {
        return selectedDonation.amount / 100;
      } else {
        return customAmountValue;
      }
    } else if (customAmountSelected && selectedGratuity.amount > 0) {
      return selectedGratuity.amount / 100;
    } else {
      return customAmountValue;
    }
  };

  const isAmountSelected = (amount: number | null) => {
    if (amount === null) {
      return false;
    }
    if (isPercentage) {
      return variant === 'gratuity' && selectedGratuity.percentage === amount;
    } else {
      return (
        (variant === 'donation' && selectedDonation.amount === amount * 100) ||
        (variant === 'gratuity' && selectedGratuity.amount === amount * 100)
      );
    }
  };

  const amountButtonStyles = {
    backgroundColor: 'leastReadableOnWebsiteBackground',
    border: 'mostReadableOnWebsiteBackgroundBorder',
    color: 'mostReadableOnWebsiteBackground',

    '&:hover, &:focus, &:first-child:active, &.selected': {
      backgroundColor: 'mostReadableOnWebsiteBackground',
      border: 'mostReadableOnWebsiteBackgroundBorder',
      color: 'leastReadableOnWebsiteBackground',

      '& .check': {
        '& .a': {
          fill: 'mostReadableOnWebsiteBackground',
        },
        '& .b': {
          fill: 'leastReadableOnWebsiteBackground',
        },
        '& .c': {
          fill: 'leastReadableOnWebsiteBackground',
        },
      },
    },
  };
  return (
    <div className='amount-selector' data-testid='amount-selector'>
      {values && (
        <Grid className='amount-selector-buttons' columns={3}>
          {values.map((value) => {
            const amount = value;
            const isSelected =
              !customAmountSelected && isAmountSelected(amount);
            return (
              <div className='amount-col' key={`amount-${amount}`}>
                <Button
                  variant='link'
                  className={classnames(
                    'amount-selector-button',
                    isSelected && 'selected'
                  )}
                  onClick={() => handleAmountSelect(amount)}
                  title={getAmountButtonTitle(
                    amount,
                    selectedText,
                    currencySymbol,
                    isSelected,
                    isPercentage
                  )}
                  data-testid={`amount-selector-button-${amount}`}
                  sx={{ ...amountButtonStyles }}
                >
                  <Flex sx={{ alignItems: 'center', justifyContent: 'center' }}>
                    {isSelected && <RadioSelectedSvg className='check' />}
                    <div>
                      {isPercentage ? (
                        <>
                          +{amount}%
                          <p className='small'>
                            (
                            {amount &&
                              displayPrice(
                                getGratuityInCents(
                                  {
                                    isCustomAmount: false,
                                    amount: 0,
                                    percentage: amount,
                                  },
                                  selectedConcessions,
                                  ticketTypes
                                ),
                                currencyConfig
                              )}
                            )
                          </p>
                        </>
                      ) : (
                        <>
                          {currencySymbol}
                          {amount}
                        </>
                      )}
                    </div>
                  </Flex>
                </Button>
              </div>
            );
          })}
        </Grid>
      )}
      <div className='amount-selector-buttons'>
        <div className='amount-selector-custom-wrapper'>
          <Overlay
            placement='top'
            show={showCustomAmountTooltip}
            target={customAmountRef}
          >
            <Tooltip
              data-testid='custom-amount-tooltip'
              id='maxAmountLimitReached'
            >{`${amountContent.amountSelectorCustomAmountTooltipText} ${currencySymbol}${maxAmount}.`}</Tooltip>
          </Overlay>
          <Button
            variant='link'
            onClick={() => handleCustomAmountSelect()}
            className={classnames(
              'amount-selector-button',
              customAmountSelected && 'selected'
            )}
            title={customAmountTitle}
            data-testid='custom-amount-button'
            sx={{
              ...amountButtonStyles,
              '& input': {
                border: 0,
                borderBottomWidth: '2px',
                borderBottomStyle: 'solid',
                borderBottomColor: 'leastReadableOnWebsiteBackground',
                color: 'leastReadableOnWebsiteBackground',
              },
            }}
          >
            <Flex sx={{ justifyContent: 'center' }}>
              <div>
                <label ref={customAmountRef}>
                  {customAmountSelected && (
                    <RadioSelectedSvg className='check' />
                  )}
                  {customLabel} {currencySymbol}
                </label>
              </div>

              {customAmountSelected && (
                <Box sx={{ marginTop: '2px', ml: 1 }}>
                  <input
                    id='customAmountInput'
                    data-testid='custom-amount-input'
                    placeholder='0'
                    value={customAmountInputValue()}
                    onClick={(e) => e.stopPropagation()}
                    onChange={handleCustomAmountChange}
                    type='tel'
                    min='1'
                    max={maxAmount}
                    autoFocus
                  />
                </Box>
              )}
            </Flex>
          </Button>
        </div>
      </div>
    </div>
  );
};

export default AmountSelector;
