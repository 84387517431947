/** @jsxImportSource theme-ui */
import React from 'react';

import { Flex } from 'theme-ui';

import { SeasonPassMovie } from '../../../@types/modelTypes';

interface Props {
  movie: SeasonPassMovie;
  children: React.ReactNode;
}

const SeasonPassMovieContainer: React.FC<Props> = ({ movie, children }) => {
  const posterUrl = movie.imageUrl
    ? movie.imageUrl
    : `${window.location.origin}/images/film-placeholder.png`;

  return (
    <div
      className='season-pass-movie-item'
      sx={{
        borderBottom: 'bodySeparator',
        my: 4,
        pb: 2,
        width: '100%',
      }}
    >
      <Flex>
        <div
          sx={{
            img: {
              width: '100px',
            },
          }}
        >
          <img src={posterUrl} alt={movie.title} />
        </div>
        <div className='details-right' sx={{ ml: 4, width: '100%' }}>
          <h3 sx={{ my: 2 }}>{movie.title}</h3>
          {children}
        </div>
      </Flex>
    </div>
  );
};

export default SeasonPassMovieContainer;
