/** @jsxImportSource theme-ui */
import React, { useState, useEffect } from 'react';

import classNames from 'classnames';
import moment from 'moment';
import { Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import {
  SeasonPassMovie,
  SeasonPassMovieSession,
} from '../../../@types/modelTypes';
import { timeFormat } from '../../../services/Helpers';
import { actionCreators } from '../../../store/ActionCreators';
import {
  selectSelectedLanguageCulture,
  selectSelectedSeasonPass,
} from '../../../store/Selectors';
import { ReactComponent as RadioSelectedSvg } from '../../../svgs/radioSelected.svg';

interface Props {
  session: SeasonPassMovieSession;
  movie: SeasonPassMovie;
}

const SeasonPassSelectSessionButton: React.FC<Props> = ({ session, movie }) => {
  const dispatch = useDispatch();

  const selectedLanguageCulture = useSelector(selectSelectedLanguageCulture);
  const { seasonPassItem, sessions } = useSelector(selectSelectedSeasonPass);
  const [isDisabled, setIsDisabled] = useState<boolean>(false);

  const maxRequiredMovies = seasonPassItem.maxRequiredMovies;
  const { notBookable, soldOut } = session;
  const existingSelectedSession = sessions.find(
    (selectedSession) => selectedSession.movie.filmId === movie.filmId
  );
  const isSelectedSession =
    existingSelectedSession?.session.sessionId === session.sessionId;

  useEffect(() => {
    const disabled = notBookable || soldOut;
    setIsDisabled(disabled);
  }, [notBookable, soldOut]);

  const handleSessionClick = () => {
    if (isSelectedSession) {
      dispatch(actionCreators.removeSelectedSeasonPassSession(session));
    } else if ((sessions?.length ?? 0) < maxRequiredMovies) {
      if (existingSelectedSession) {
        dispatch(
          actionCreators.removeSelectedSeasonPassSession(
            existingSelectedSession.session
          )
        );
      }
      dispatch(
        actionCreators.setSelectedSeasonPassSessions({ movie, session })
      );
    }
  };

  return (
    <div
      key={`${session.externalSessionId}`}
      className='session-button-container'
    >
      <Button
        onClick={handleSessionClick}
        disabled={isDisabled}
        className={classNames(
          'session-button',
          isSelectedSession && 'selected'
        )}
        sx={{
          backgroundColor: 'websiteBackground',
          borderColor: 'accent',
          color: 'mostReadableOnWebsiteBackground',
          fontWeight: 'bold',

          '&:disabled': {
            backgroundColor: 'inherit',
            borderColor: 'inherit',
            color: 'inherit',
          },
          '&:hover, &:first-child:active, &:first-child:focus-visible': {
            backgroundColor: 'accent',
            borderColor: 'accent',
            color: 'mostReadableOnAccentBackground',
          },
          '&.selected': {
            backgroundColor: 'accent',
            borderColor: 'accent',
            color: 'mostReadableOnAccentBackground',
          },
          '& .checked': {
            '& .a': { fill: 'accent' },
            '& .b': { fill: 'mostReadableOnAccentBackground' },
            '& .c': { fill: 'mostReadableOnAccentBackground' },
          },

          svg: {
            mr: 1,
            height: ['16px', '20px'],
            width: ['16px', '20px'],
          },
        }}
      >
        {isSelectedSession && <RadioSelectedSvg className='checked' />}
        <span
          sx={{
            fontSize: ['small', 'regular'],
          }}
        >
          {moment(session.startDateTime)
            .locale(selectedLanguageCulture)
            .format(timeFormat(selectedLanguageCulture))}
        </span>
      </Button>
    </div>
  );
};

export default SeasonPassSelectSessionButton;
