/** @jsxImportSource theme-ui */
import React from 'react';

import { Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Box, Flex } from 'theme-ui';

import { useScreenWidth } from '../../../contextProviders/screenWidthContext';
import {
  getTotalPriceToDisplay,
  shouldHideCartSummary,
  shouldShowCountdown,
} from '../../../services/Helpers';
import { actionCreators } from '../../../store/ActionCreators';
import {
  selectContent,
  selectCountdownEndTime,
  selectCurrencyConfig,
  selectGrandTotalAfterDiscountsInCents,
  selectJourneyTypeConfig,
  selectRefundTotalInCents,
  selectSelectedSeasonPass,
} from '../../../store/Selectors';
import { ReactComponent as CartEmpty } from '../../../svgs/cartEmpty.svg';
import { ReactComponent as CartFull } from '../../../svgs/cartFull.svg';
import Countdown from '../countdown/Countdown';

const TotalWrapper: React.FC = () => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const { isLargeScreenWidth } = useScreenWidth();

  const content = useSelector(selectContent);
  const countdownEndTime = useSelector(selectCountdownEndTime);
  const currencyConfig = useSelector(selectCurrencyConfig);
  const grandTotalWithDiscount = useSelector(
    selectGrandTotalAfterDiscountsInCents
  );
  const journeyTypeConfig = useSelector(selectJourneyTypeConfig);
  const refundTotalInCents = useSelector(selectRefundTotalInCents);
  const selectedSeasonPass = useSelector(selectSelectedSeasonPass);

  const isRefund = pathname.includes('/refund/');

  const totalPriceToDisplay = getTotalPriceToDisplay(
    isRefund,
    refundTotalInCents,
    grandTotalWithDiscount,
    false,
    journeyTypeConfig,
    selectedSeasonPass,
    currencyConfig
  );

  const shouldShowCountdownForPath = shouldShowCountdown(pathname);

  const showCountdown = countdownEndTime && shouldShowCountdownForPath;

  const hideCartSummaryForPath = shouldHideCartSummary(pathname);

  const showSmallScreenCartSummary =
    !hideCartSummaryForPath && !isLargeScreenWidth;

  const showTotalWrapper = showSmallScreenCartSummary || showCountdown;

  if (!showTotalWrapper) return null;

  return (
    <Flex
      className='total-wrapper'
      data-testid='totalwrapper'
      sx={{
        backgroundColor: 'totalBarBackground',
        color: 'mostReadableOnTotalBarBackground',
        alignContent: 'center',
        mx: -3,
        justifyContent: isLargeScreenWidth ? 'center' : 'flex-start',
        '.total-text': {
          fontFamily: 'heading',
        },

        '.countdown-wrapper': {
          '.countdown-container': {
            backgroundColor: 'totalBarBackgroundLighter',
            border: 'mostReadableOnTotalBarBackgroundBorder',
          },
        },
      }}
    >
      {isLargeScreenWidth ? (
        <Box>
          <span className='total-text' sx={{ px: 4 }}>
            {`${content?.payment?.totalToPayText} : ${totalPriceToDisplay}`}
          </span>
        </Box>
      ) : (
        <>
          <Box
            className='cart-button'
            sx={{
              svg: {
                fill: 'mostReadableOnTotalBarBackground',
              },
            }}
          >
            <Button
              onClick={() => dispatch(actionCreators.setCartSummaryOpen(true))}
              variant='link'
              sx={{ display: 'flex' }}
            >
              {grandTotalWithDiscount ? <CartFull /> : <CartEmpty />}
            </Button>
          </Box>
          <Box className='total-text' sx={{ p: 0, textAlign: 'left' }}>
            {totalPriceToDisplay}
          </Box>
        </>
      )}
      <Box className='countdown-wrapper'>
        <Countdown />
      </Box>
    </Flex>
  );
};

export default TotalWrapper;
