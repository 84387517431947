/** @jsxImportSource theme-ui */
import React from 'react';

import { Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Flex } from 'theme-ui';

import {
  GlobalState,
  LoyaltyRedemptionContent,
} from '../../../../@types/modelTypes';
import { actionCreators } from '../../../../store/ActionCreators';
import { selectDisplayPrice } from '../../../../store/Selectors';

interface Props {
  grandTotal: number;
  setPaymentFormVisibility: (visible: boolean) => void;
  setGiftCardFormVisibility?: (visible: boolean) => void;
  setLoyaltyRedemptionApplied: (applied: boolean) => void;
  loyaltyRedemptionContent: LoyaltyRedemptionContent;
}

const LoyaltyRedemptionBreakDown: React.FC<Props> = ({
  grandTotal,
  setPaymentFormVisibility,
  setGiftCardFormVisibility,
  setLoyaltyRedemptionApplied,
  loyaltyRedemptionContent,
}) => {
  const dispatch = useDispatch();
  const grandTotalToDisplay = useSelector((state: GlobalState) =>
    selectDisplayPrice(state, grandTotal)
  );
  const handleRemoveLoyaltyRedemption = () => {
    setLoyaltyRedemptionApplied(false);
    dispatch(actionCreators.setLoyaltyRedemptionRewards(0));
    setPaymentFormVisibility(true);
    setGiftCardFormVisibility && setGiftCardFormVisibility(true);
  };

  return (
    <Box
      className='breakdown'
      data-testid='loyalty-redemption-break-down'
      mt={5}
    >
      <p sx={{ textAlign: 'center', fontWeight: 'bold' }}>
        {loyaltyRedemptionContent.loyaltyRedemptionDollarsAppliedText}
      </p>
      <Flex
        sx={{ py: 5, justifyContent: 'space-between', alignItems: 'center' }}
      >
        <Flex sx={{ flexGrow: 1, alignItems: 'center', mr: 3 }}>
          <strong>
            {loyaltyRedemptionContent.loyaltyRedemptionDollarsAppliedLabel}
          </strong>
        </Flex>
        <Flex sx={{ alignItems: 'center', mr: 3 }}>
          <Button
            onClick={handleRemoveLoyaltyRedemption}
            variant='link'
            size='sm'
            className='float-center'
          >
            {loyaltyRedemptionContent.removeButtonText}
          </Button>
        </Flex>
        <Box sx={{ alignItems: 'center', justifyContent: 'flex-end' }}>
          <span className='float-end'>{grandTotalToDisplay}</span>
        </Box>
      </Flex>
    </Box>
  );
};

export default LoyaltyRedemptionBreakDown;
