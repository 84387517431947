import React from 'react';

import classnames from 'classnames';
import { useSelector } from 'react-redux';
import { Grid } from 'theme-ui';

import SeasonPassMovieContainer from './SeasonPassMovieContainer';
import SeasonPassSelectSeatContainer from './SeasonPassSelectSeatContainer';
import SeasonPassSelectSessionButton from './SeasonPassSelectSessionButton';
import SeasonPassSessionContainer from './SeasonPassSessionContainer';

import { SeasonPassMovie } from '../../../@types/modelTypes';
import { SEASON_PASS_STEPS } from '../../../constants';
import { selectStep } from '../../../store/Selectors';

interface Props {
  movie: SeasonPassMovie;
}

const SeasonPassMovieItem: React.FC<Props> = ({ movie }) => {
  const step = useSelector(selectStep);

  const isShowtimesStep = step === SEASON_PASS_STEPS.SHOWTIMES;
  const isSeatsStep = step === SEASON_PASS_STEPS.SEATS;

  return (
    <SeasonPassMovieContainer movie={movie}>
      {movie.showTimes.map((showtime) => (
        <SeasonPassSessionContainer key={showtime.date} showtime={showtime}>
          <Grid
            className={classnames(
              isShowtimesStep && 'season-pass-session-button-container'
            )}
            sx={{ mt: 1 }}
            columns={[isShowtimesStep ? 2 : 1]}
          >
            {showtime.times.map((session) => (
              <div key={`${session.externalSessionId}`} className='showtime'>
                {isShowtimesStep && (
                  <SeasonPassSelectSessionButton
                    session={session}
                    movie={movie}
                  />
                )}

                {isSeatsStep && (
                  <SeasonPassSelectSeatContainer
                    sessionId={session.sessionId}
                  />
                )}
              </div>
            ))}
          </Grid>
        </SeasonPassSessionContainer>
      ))}
    </SeasonPassMovieContainer>
  );
};

export default SeasonPassMovieItem;
