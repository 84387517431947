import React, { FC, memo } from 'react';

import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import Donations from './Donations';
import messages from './intl';

import { DonationConfig, DonationContent } from '../../../@types/modelTypes';
import {
  isIosApp,
  selectDazzlerLocale,
  selectIsCustomerReadyForPayment,
  selectIsPaymentPageValidated,
} from '../../../store/Selectors';
import { resolveLocalisedStringOrDefault } from '../helpers';
import globalMessages from '../intl';
import { WidgetData } from '../types';

type Props = {
  widget: WidgetData<'TicketingCMSJourneyDonationWidget'>;
};

export const DonationWidget: FC<Props> = ({ widget }) => {
  const { formatMessage } = useIntl();
  const locale = useSelector(selectDazzlerLocale);
  const isIOSApp = useSelector(isIosApp);

  const isPageValidated = useSelector(selectIsPaymentPageValidated);
  const isCustomerReadyForPayment = useSelector(
    selectIsCustomerReadyForPayment
  );

  const isReadyForPayment = isPageValidated && isCustomerReadyForPayment;

  if (!isReadyForPayment || isIOSApp) return;

  const donationConfig: DonationConfig = {
    enableDonationRoundUpField: widget.shape?.enableDonationRoundup,
    donationValues: widget.shape?.donationValues,
  };

  const donationContent: DonationContent = {
    donationSubTitle: resolveLocalisedStringOrDefault(
      formatMessage(messages.donationSubTitle),
      locale,
      widget.shape?.donationSubTitle
    ),
    donationText: resolveLocalisedStringOrDefault(
      formatMessage(messages.donationText),
      locale,
      widget.shape?.donationText
    ),
    donationRoundUpRichText: resolveLocalisedStringOrDefault(
      formatMessage(messages.donationRoundUpRichText),
      locale,
      widget.shape?.donationRoundUpRichText
    ),
    amountSelectorCustomAmountLabel: formatMessage(
      globalMessages.amountSelectorCustomAmountLabel
    ),
    amountSelectorSelectedText: formatMessage(
      globalMessages.amountSelectorSelectedText
    ),
    amountSelectorCustomAmountTooltipText: formatMessage(
      globalMessages.amountSelectorCustomAmountTooltipText
    ),
  };

  return (
    <Donations
      donationConfig={donationConfig}
      donationContent={donationContent}
    />
  );
};

export default memo(DonationWidget);
