/** @jsxImportSource theme-ui */
import React, { useState, useRef } from 'react';

import classnames from 'classnames';
import { Button, Overlay, Tooltip } from 'react-bootstrap';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Flex } from 'theme-ui';
import { isNumeric } from 'validator';

import {
  PERCENTAGE_OF_TOTAL_ORDER_COST,
  PERCENTAGE_OF_F_AND_B_ITEMS_COST_ONLY,
} from './constants';

import { TicketingCmsJourneyGratuityWidgetGratuityType } from '../../../@types/dazzlerTypes';
import {
  displayPrice,
  getAmountButtonTitle,
  getCustomAmountSelectedTitle,
  getGratuityInCents,
} from '../../../services/Helpers';
import {
  selectCurrencyConfig,
  selectCurrencySymbol,
  selectSelectedFaBConcessions,
  selectSelectedGratuity,
  selectTicketTypes,
} from '../../../store/Selectors';
import { ReactComponent as RadioSelectedSvg } from '../../../svgs/radioSelected.svg';
import globalMessages from '../intl';

interface Props {
  gratuityType: TicketingCmsJourneyGratuityWidgetGratuityType | undefined;
  maxAmount: number;
  setAmount: (
    isCustomAmount: boolean,
    amount: number,
    percentage: number,
    gratuityType: TicketingCmsJourneyGratuityWidgetGratuityType | undefined
  ) => void;
  values: readonly (number | null)[] | undefined;
}

const GratuityAmountSelector: React.FC<Props> = ({
  gratuityType,
  maxAmount,
  setAmount,
  values,
}) => {
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();
  const customAmountRef = useRef(null);

  const selectedConcessions = useSelector(selectSelectedFaBConcessions);
  const selectedGratuity = useSelector(selectSelectedGratuity);
  const ticketTypes = useSelector(selectTicketTypes);

  const [amountSelected, setAmountSelected] = useState<number | null>(null);
  const [customAmountSelected, setCustomAmountSelected] = useState(
    selectedGratuity.isCustomAmount
  );
  const [customAmountValue, setCustomAmountValue] = useState('');
  const [showCustomAmountTooltip, setShowCustomAmountTooltip] = useState(false);
  const currencyConfig = useSelector(selectCurrencyConfig);
  const currencySymbol = useSelector(selectCurrencySymbol);

  const isGratuityTypePercentage =
    gratuityType === PERCENTAGE_OF_TOTAL_ORDER_COST ||
    gratuityType === PERCENTAGE_OF_F_AND_B_ITEMS_COST_ONLY;

  const parseNumber = (value: string) => {
    const parsedValue = Number.parseFloat(value);
    const num = parsedValue > 0 ? parsedValue : 0;
    return num;
  };

  const handleCustomAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const nextCustomAmountValue = e.target.value;
    if (nextCustomAmountValue === '' || isNumeric(nextCustomAmountValue)) {
      if (parseNumber(nextCustomAmountValue) > maxAmount) {
        setShowCustomAmountTooltip(true);
        setTimeout(() => {
          setShowCustomAmountTooltip(false);
        }, 4000);
        return;
      } else {
        setShowCustomAmountTooltip(false);
      }
      setCustomAmountValue(nextCustomAmountValue);
      dispatch(
        setAmount(
          true,
          parseNumber(nextCustomAmountValue) * 100,
          0,
          gratuityType
        )
      );
    }
  };

  const handleCustomAmountSelect = () => {
    setAmountSelected(null);
    const toggleButtonOn = customAmountSelected === false;
    setCustomAmountSelected(!!toggleButtonOn);
    dispatch(
      setAmount(
        true,
        toggleButtonOn ? parseNumber(customAmountValue) * 100 : 0,
        0,
        gratuityType
      )
    );
  };

  const handleAmountSelect = (amount: number) => {
    setCustomAmountSelected(false);
    const toggleButtonOn = amountSelected !== amount;
    setAmountSelected(toggleButtonOn ? amount : null);
    setShowCustomAmountTooltip(false);
    if (isGratuityTypePercentage) {
      dispatch(setAmount(false, 0, toggleButtonOn ? amount : 0, gratuityType));
    } else {
      dispatch(
        setAmount(false, toggleButtonOn ? amount * 100 : 0, 0, gratuityType)
      );
    }
  };

  const customLabel = formatMessage(
    globalMessages.amountSelectorCustomAmountLabel
  );

  const selectedText = formatMessage(globalMessages.amountSelectorSelectedText);

  const tooltipText = formatMessage(
    globalMessages.amountSelectorCustomAmountTooltipText
  );

  const customAmountTitle = customAmountSelected
    ? getCustomAmountSelectedTitle(
        currencySymbol,
        customLabel,
        customAmountValue,
        selectedText
      )
    : customLabel;

  const customAmountInputValue = () => {
    if (customAmountSelected && selectedGratuity.amount > 0) {
      return selectedGratuity.amount / 100;
    } else {
      return customAmountValue;
    }
  };

  const isAmountSelected = (amount: number) => {
    if (isGratuityTypePercentage) {
      return selectedGratuity.percentage === amount;
    } else {
      return selectedGratuity.amount === amount * 100;
    }
  };

  const amountButtonStyles = {
    backgroundColor: 'leastReadableOnWebsiteBackground',
    border: 'mostReadableOnWebsiteBackgroundBorder',
    color: 'mostReadableOnWebsiteBackground',

    '&:hover, &:focus, &:first-child:active, &.selected': {
      backgroundColor: 'mostReadableOnWebsiteBackground',
      border: 'mostReadableOnWebsiteBackgroundBorder',
      color: 'leastReadableOnWebsiteBackground',

      '& .check': {
        '& .a': {
          fill: 'mostReadableOnWebsiteBackground',
        },
        '& .b': {
          fill: 'leastReadableOnWebsiteBackground',
        },
        '& .c': {
          fill: 'leastReadableOnWebsiteBackground',
        },
      },
    },
  };

  const getDisplayPrice = (amount: number) => {
    return displayPrice(
      getGratuityInCents(
        {
          isCustomAmount: false,
          amount: 0,
          percentage: amount,
          gratuityType,
        },
        selectedConcessions,
        ticketTypes
      ),
      currencyConfig
    );
  };

  const getAmountButtons = (values: readonly (number | null)[] | undefined) => {
    return (
      values && (
        <Flex
          className='amount-selector-buttons'
          sx={{ marginY: 0, marginX: -2 }}
        >
          {values.map((value) => {
            const amount = value ?? 0;
            const isSelected =
              !customAmountSelected && isAmountSelected(amount);

            return (
              <Flex
                className='amount-col'
                key={`amount-${amount}`}
                sx={{ width: '100%' }}
              >
                <Button
                  variant='link'
                  className={classnames(
                    'amount-selector-button',
                    isSelected && 'selected'
                  )}
                  onClick={() => handleAmountSelect(amount)}
                  title={getAmountButtonTitle(
                    amount,
                    selectedText,
                    currencySymbol,
                    isSelected,
                    isGratuityTypePercentage
                  )}
                  data-testid={`amount-selector-button-${amount}`}
                  sx={{ ...amountButtonStyles, mx: 2 }}
                >
                  <Flex sx={{ alignItems: 'center', justifyContent: 'center' }}>
                    {isSelected && <RadioSelectedSvg className='check' />}
                    <div>
                      {isGratuityTypePercentage ? (
                        <>
                          +{amount}%
                          <p className='small'>{getDisplayPrice(amount)}</p>
                        </>
                      ) : (
                        <>
                          {currencySymbol}
                          {amount}
                        </>
                      )}
                    </div>
                  </Flex>
                </Button>
              </Flex>
            );
          })}
        </Flex>
      )
    );
  };

  return (
    <div className='amount-selector' data-testid='amount-selector'>
      {getAmountButtons(values)}

      <div className='amount-selector-buttons'>
        <div className='amount-selector-custom-wrapper'>
          <Overlay
            placement='top'
            show={showCustomAmountTooltip}
            target={customAmountRef}
          >
            <Tooltip
              data-testid='custom-amount-tooltip'
              id='maxAmountLimitReached'
            >{`${tooltipText} ${currencySymbol}${maxAmount}.`}</Tooltip>
          </Overlay>
          <Button
            variant='link'
            onClick={() => handleCustomAmountSelect()}
            className={classnames(
              'amount-selector-button',
              customAmountSelected && 'selected'
            )}
            title={customAmountTitle}
            data-testid='custom-amount-button'
            sx={{
              ...amountButtonStyles,
              '& input': {
                border: 0,
                borderBottomWidth: '2px',
                borderBottomStyle: 'solid',
                borderBottomColor: 'leastReadableOnWebsiteBackground',
                color: 'leastReadableOnWebsiteBackground',
              },
            }}
          >
            <Flex sx={{ justifyContent: 'center' }}>
              <div>
                <label ref={customAmountRef}>
                  {customAmountSelected && (
                    <RadioSelectedSvg className='check' />
                  )}
                  {customLabel} {currencySymbol}
                </label>
              </div>

              {customAmountSelected && (
                <Box sx={{ marginTop: '2px', ml: 1 }}>
                  <input
                    id='customAmountInput'
                    data-testid='custom-amount-input'
                    placeholder='0'
                    value={customAmountInputValue()}
                    onClick={(e) => e.stopPropagation()}
                    onChange={handleCustomAmountChange}
                    type='tel'
                    min='1'
                    max={maxAmount}
                    autoFocus
                  />
                </Box>
              )}
            </Flex>
          </Button>
        </div>
      </div>
    </div>
  );
};

export default GratuityAmountSelector;
