import { defineMessages } from 'react-intl';

export default defineMessages({
  loyaltyRecognitionSubtitle: {
    id: 'loyalty-card-number-entry.loyalty-recognition-subtitle-text',
    defaultMessage: 'Have a loyalty account?',
  },
  loyaltyRecognitionHeading: {
    id: 'loyalty-card-number-entry.loyalty-recognition-heading',
    defaultMessage: 'Enter your details & earn rewards',
  },
  loyaltyRecognitionLabel: {
    id: 'loyalty-card-number-entry.loyalty-recognition-label',
    defaultMessage: 'Enter your loyalty number here',
  },
  loyaltyRecognitionPlaceHolder: {
    id: 'loyalty-card-number-entry.loyalty-recognition-placeholder',
    defaultMessage: 'Loyalty number',
  },
  loyaltyRecognitionErrorText: {
    id: 'loyalty-card-number-entry.loyalty-recognition-error-text',
    defaultMessage: 'This loyalty number is not recognized',
  },
  loyaltyRecognitionSuccessText: {
    id: 'loyalty-card-number-entry.loyalty-recognition-success-text',
    defaultMessage: 'Your account will be rewarded with loyalty points',
  },
  loyaltyRecognitionTicketCreditLabel: {
    id: 'loyalty-card-number-entry.loyalty-recognition-ticket-credit-label',
    defaultMessage: 'Reward Ticket Credit',
  },
  loyaltyRecognitionTicketCreditsAvailableLabel: {
    id: 'loyalty-card-number-entry.loyalty-recognition-ticket-credits-available-label',
    defaultMessage: 'reward ticket(s) available',
  },
  loyaltyRecognitionTicketCreditsRichText: {
    id: 'loyalty-card-number-entry.loyalty-recognition-ticket-credits-rich-text',
    defaultMessage:
      'Select how many of your <strong>##TICKETCREDITSAVAILABLE## reward ticket(s)</strong> you would like to use. They will automatically be redeemed against highest cost tickets.',
  },
  loyaltyNumberErrorRichText: {
    id: 'loyalty-card-number-entry.loyalty-number-error-rich-text',
    defaultMessage:
      'There was a problem checking your membership number. Please try again.',
  },
});
