import React, { FC, memo } from 'react';

import { useSelector } from 'react-redux';
import { Box } from 'theme-ui';

import { selectCurrentCinema } from '../../../store/Selectors';
import { WidgetData } from '../types';

type Props = {
  widget: WidgetData<'TicketingCMSJourneyTheaterDetailsWidget'>;
};

export const TheaterDetails: FC<Props> = ({ widget }) => {
  const currentCinema = useSelector(selectCurrentCinema);

  if (!currentCinema) return null;
  return (
    <Box
      data-testid='address'
      className='contained'
      sx={{ px: 3, textAlign: 'left' }}
    >
      {widget.shape?.showName && currentCinema.title && (
        <p>{currentCinema.title}</p>
      )}
      {widget.shape?.showAddress && (
        <>
          <p>{currentCinema.title}</p>
          <p>{currentCinema.address1}</p>
          <p>
            {currentCinema.city}
            {', '}
            {currentCinema.stateName} {currentCinema.zipCode}
          </p>
        </>
      )}
      {widget.shape?.showEmail && currentCinema.email && (
        <p>{currentCinema.email}</p>
      )}
      {widget.shape?.showPhone && currentCinema.phone && (
        <p>{currentCinema.phone}</p>
      )}
    </Box>
  );
};

export default memo(TheaterDetails);
