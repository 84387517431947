import React, { FC, memo, useState } from 'react';

import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import messages from './intl';
import LoyaltyRedemption from './loyaltycomponents/LoyaltyRedemption';
import SplitLoyaltyRedemption from './loyaltycomponents/SplitLoyaltyRedemption';

import {
  GlobalState,
  LoyaltyRedemptionConfig,
  LoyaltyRedemptionContent,
} from '../../../@types/modelTypes';
import { actionCreators } from '../../../store/ActionCreators';
import {
  selectBookingData,
  selectConfig,
  selectDazzlerLocale,
  selectDisplayPrice,
  selectGrandTotalAfterDiscountsInCents,
  selectGrandTotalWithoutDonationInCents,
  selectHasCardPaymentPromoTicketsInCart,
  selectIsCustomerReadyForPayment,
  selectIsPaymentPageValidated,
  selectJourneyTypeConfig,
} from '../../../store/Selectors';
import ContainedRow from '../../common/layout/ContainedRow';
import { resolveLocalisedStringOrDefault } from '../helpers';
import globalMessages from '../intl';
import BorderedCollapse from '../shared/borderedcollapse/BorderedCollapse';
import { WidgetData } from '../types';

type Props = {
  widget: WidgetData<'TicketingCMSJourneyLoyaltyRedemptionWidget'>;
};

export const LoyaltyRedemptionWidget: FC<Props> = ({ widget }) => {
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();
  const locale = useSelector(selectDazzlerLocale);
  const config = useSelector(selectConfig);
  const bookingData = useSelector(selectBookingData);
  const journeyTypeConfig = useSelector(selectJourneyTypeConfig);

  const grandTotalWithoutDonation = useSelector(
    selectGrandTotalWithoutDonationInCents
  );
  const grandTotal = useSelector(selectGrandTotalAfterDiscountsInCents);
  const [expandRedemptionForm, setExpandRedemptionForm] = useState(
    bookingData.loyaltyCardBalance > 0
  );
  const setPaymentFormVisibility = (nextShowPaymentForm: boolean) => {
    dispatch(actionCreators.setShowPaymentForm(nextShowPaymentForm));
  };
  const setGiftCardFormVisibility = (nextShowGiftCardForm: boolean) => {
    dispatch(actionCreators.setShowGiftCardForm(nextShowGiftCardForm));
  };
  const isPageValidated = useSelector(selectIsPaymentPageValidated);
  const isCustomerReadyForPayment = useSelector(
    selectIsCustomerReadyForPayment
  );

  const isReadyForPayment = isPageValidated && isCustomerReadyForPayment;

  const showSplitLoyaltyRedemption =
    config.currentCinema.enableLoyaltyRedemption &&
    widget.shape?.allowSplitLoyaltyRedemption &&
    grandTotalWithoutDonation > 0 &&
    bookingData.isUserValidated &&
    !journeyTypeConfig.hidesLoyaltyRedemption;

  const showLoyaltyRedemptionFullPayment =
    config.currentCinema.enableLoyaltyRedemption &&
    !widget.shape?.allowSplitLoyaltyRedemption &&
    grandTotalWithoutDonation > 0;

  const hasCardPaymentPromotionTicketInCart = useSelector(
    selectHasCardPaymentPromoTicketsInCart
  );

  const handleValidatePage = () => {
    dispatch(actionCreators.setIsPaymentPageValidated(true));
  };

  const priceToDisplay = useSelector((state: GlobalState) =>
    selectDisplayPrice(
      state,
      Math.min(bookingData.loyaltyCardBalance, grandTotal)
    )
  );

  const loyaltyRedemptionConfig: LoyaltyRedemptionConfig = {
    enableCustomAmountLoyaltyRedemption:
      widget.shape?.enableCustomAmountLoyaltyRedemption,
  };

  const loyaltyRedemptionContent: LoyaltyRedemptionContent = {
    loyaltyRedemptionHeading: resolveLocalisedStringOrDefault(
      formatMessage(messages.loyaltyRedemptionHeading),
      locale,
      widget.shape?.loyaltyRedemptionHeading
    ),
    loyaltyRedemptionRichText: resolveLocalisedStringOrDefault(
      formatMessage(messages.loyaltyRedemptionRichText),
      locale,
      widget.shape?.loyaltyRedemptionRichText
    ),
    loyaltyRedemptionButtonText: resolveLocalisedStringOrDefault(
      formatMessage(messages.loyaltyRedemptionButtonText),
      locale,
      widget.shape?.loyaltyRedemptionButtonText
    ),
    loyaltyRedemptionDollarsAppliedText: resolveLocalisedStringOrDefault(
      formatMessage(messages.loyaltyRedemptionDollarsAppliedText),
      locale,
      widget.shape?.loyaltyRedemptionDollarsAppliedText
    ),
    loyaltyRedemptionNotEnoughBalanceText: resolveLocalisedStringOrDefault(
      formatMessage(messages.loyaltyRedemptionNotEnoughBalanceText),
      locale,
      widget.shape?.loyaltyRedemptionNotEnoughBalanceText
    ),
    customLoyaltyRichText: resolveLocalisedStringOrDefault(
      formatMessage(messages.customLoyaltyRichText),
      locale,
      widget.shape?.customLoyaltyRichText
    ),
    loyaltyRedemptionZeroBalanceHeading: formatMessage(
      messages.loyaltyRedemptionZeroBalanceHeading
    ),
    loyaltyRedemptionDollarsAppliedLabel: formatMessage(
      messages.loyaltyRedemptionDollarsAppliedLabel
    ),
    loyaltyRedemptionRemainingBalanceRichText: formatMessage(
      messages.loyaltyRedemptionRemainingBalanceRichText
    ),
    loyaltyRedemptionStillToPayRichText: formatMessage(
      messages.loyaltyRedemptionStillToPayRichText
    ),
    customLoyaltyValueToApplyLabel: formatMessage(
      messages.customLoyaltyValueToApplyLabel
    ),
    customLoyaltyValueInputPlaceholder: formatMessage(
      messages.customLoyaltyValueInputPlaceholder
    ),
    customLoyaltyValueTooltipText: formatMessage(
      messages.customLoyaltyValueTooltipText
    ),
    removeButtonText: formatMessage(globalMessages.removeButtonText),
    submitText: formatMessage(globalMessages.submitPaymentText),
  };

  if (!isReadyForPayment) return;

  return (
    <>
      {!hasCardPaymentPromotionTicketInCart && showSplitLoyaltyRedemption && (
        <ContainedRow
          classNameWrapper='loyalty-redemption-wrapper'
          styles={{ my: 5 }}
        >
          {bookingData.loyaltyCardBalance > 0 ? (
            <BorderedCollapse
              closeButtonText={formatMessage(globalMessages.closeButtonText)}
              heading={loyaltyRedemptionContent.loyaltyRedemptionHeading.replaceAll(
                '##LoyaltyCreditApplied##',
                priceToDisplay
              )}
              setShow={setExpandRedemptionForm}
              show={expandRedemptionForm}
            >
              <SplitLoyaltyRedemption
                isPageValidated={isPageValidated}
                handleValidatePage={handleValidatePage}
                setPaymentFormVisibility={setPaymentFormVisibility}
                setGiftCardFormVisibility={setGiftCardFormVisibility}
                loyaltyRedemptionConfig={loyaltyRedemptionConfig}
                loyaltyRedemptionContent={loyaltyRedemptionContent}
              />
            </BorderedCollapse>
          ) : (
            <div
              className='loyalty-redemption-disabled'
              sx={{
                textTransform: 'uppercase',
                fontWeight: 'bold',
                textAlign: 'center',
                backgroundColor: 'boxBackground',
                borderColor: 'boxBorderColor',
                color: 'mostReadableOnWebsiteBackground',
              }}
            >
              <h3>
                {formatMessage(messages.loyaltyRedemptionZeroBalanceHeading)}
              </h3>
            </div>
          )}
        </ContainedRow>
      )}

      {showLoyaltyRedemptionFullPayment && (
        <ContainedRow classNameWrapper='loyalty-redemption-wrapper'>
          <LoyaltyRedemption
            isPageValidated={isPageValidated}
            handleValidatePage={handleValidatePage}
            setPaymentFormVisibility={setPaymentFormVisibility}
            setGiftCardFormVisibility={setGiftCardFormVisibility}
            loyaltyRedemptionContent={loyaltyRedemptionContent}
          />
        </ContainedRow>
      )}
    </>
  );
};

export default memo(LoyaltyRedemptionWidget);
