import React from 'react';

import classnames from 'classnames';
import { useSelector } from 'react-redux';

import {
  SeatMapRow,
  SeatMapSeat,
  SeatButtonStyle,
  SeatMapAreaDimensions,
  GlobalState,
} from '../../../../@types/modelTypes';
import { SEAT_STYLE } from '../../../../constants';
import { isSeatSelected } from '../../../../services/SeatMapHelpers';
import {
  selectIsSeatsFirstJourney,
  selectSeatsAvailableInArea,
  selectSeatsModel,
  selectSelectedSeats,
} from '../../../../store/Selectors';
import SeatButton from '../SeatButton';

interface Props {
  seat: SeatMapSeat;
  index: number;
  row: SeatMapRow;
  rowIndex: number;
  forceSeatText: boolean;
  handleSeatClick: (seat: SeatMapSeat) => void;
  setActiveRowNumber: (number: number | null) => void;
  seatButtonStyle: SeatButtonStyle;
  className: string;
  areaLabel: string | undefined;
  seatMapAreaDimensions: SeatMapAreaDimensions;
  activeRow: boolean;
  overrideSoldAsGroup: boolean;
}

const SeatContainer: React.FC<Props> = ({
  seat,
  index,
  row,
  rowIndex,
  forceSeatText: forceSeatNumbers,
  handleSeatClick,
  setActiveRowNumber,
  seatButtonStyle,
  className,
  areaLabel,
  seatMapAreaDimensions,
  activeRow,
  overrideSoldAsGroup,
}) => {
  const seatsModel = useSelector(selectSeatsModel);
  const selectedSeats = useSelector(selectSelectedSeats);
  const seatsAvailableInArea = useSelector((state: GlobalState) =>
    selectSeatsAvailableInArea(state, seat.areaCategoryCode)
  );
  const isSeatsFirstJourney = useSelector(selectIsSeatsFirstJourney);

  const isSelected = isSeatSelected(seat, selectedSeats);

  const areaProhibited =
    !isSeatsFirstJourney &&
    !(seat.areaCategoryCode && seatsModel && seatsAvailableInArea);

  function isThreeSeatSofa() {
    const nextSeat = row.seats[index + 1];
    const previousSeat = row.seats[index - 1];
    let isThreeSeatSofa = false;
    isThreeSeatSofa =
      (nextSeat && nextSeat.style === SEAT_STYLE.SOFA_MIDDLE) ||
      (previousSeat && previousSeat.style === SEAT_STYLE.SOFA_MIDDLE);
    return isThreeSeatSofa;
  }

  if (!seat.seatData || !seatMapAreaDimensions.xRatio) return null;

  return (
    <div
      className={classnames('seat-container', className)}
      onMouseEnter={() => setActiveRowNumber(seat.row)}
      onMouseLeave={() => setActiveRowNumber(null)}
      key={`seat-${rowIndex}-${index}`}
      style={{
        top:
          (seat.seatData.yPos - seatMapAreaDimensions.unusedTop) *
          seatMapAreaDimensions.xRatio,
        left:
          (seat.seatData.xPos - seatMapAreaDimensions.unusedLeft) *
          seatMapAreaDimensions.xRatio,
        width: seat.seatData.width * seatMapAreaDimensions.xRatio,
      }}
    >
      {areaLabel && (
        <div className='position-absolute area-label'>{areaLabel}</div>
      )}
      {seat.isASeat && (
        <SeatButton
          areaProhibited={areaProhibited}
          seatButtonStyle={seatButtonStyle}
          forceSeatText={forceSeatNumbers}
          handleSeatClick={handleSeatClick}
          isSelected={isSelected}
          isSelectedRow={activeRow}
          isThreeSeatSofa={isThreeSeatSofa()}
          seat={seat}
          isCoordinateBased={true}
          overrideSoldAsGroup={overrideSoldAsGroup}
          seatUnavailable={seat.isUnavailable}
        />
      )}
    </div>
  );
};

export default SeatContainer;
