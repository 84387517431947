/** @jsxImportSource theme-ui */
import React, { useEffect, useState } from 'react';

import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import { PERCENTAGE_OF_F_AND_B_ITEMS_COST_ONLY } from './constants';
import GratuityAmountSelector from './GratuityAmountSelector';
import messages from './intl';

import { GlobalState } from '../../../@types/modelTypes';
import { PEACH_CODES } from '../../../constants';
import { actionCreators } from '../../../store/ActionCreators';
import {
  selectDazzlerLocale,
  selectError,
  selectIsCustomerReadyForPayment,
  selectIsPaymentPageValidated,
  selectSelectedFaBConcessions,
  selectSelectedGratuity,
  selectShowPaymentForm,
} from '../../../store/Selectors';
import ContainedRow from '../../common/layout/ContainedRow';
import RichText from '../../common/richtext/RichText';
import { resolveLocalisedStringOrDefault } from '../helpers';
import globalMessages from '../intl';
import BorderedCollapse from '../shared/borderedcollapse/BorderedCollapse';
import { WidgetData } from '../types';

interface Props {
  widget: WidgetData<'TicketingCMSJourneyGratuityWidget'>;
}

const GratuityWidget: React.FC<Props> = ({ widget }) => {
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();

  const error = useSelector(selectError);
  const gratuityLimitInCents = useSelector(
    (state: GlobalState) => state.gratuityLimitInCents
  );
  const isCustomerReadyForPayment = useSelector(
    selectIsCustomerReadyForPayment
  );
  const isPageValidated = useSelector(selectIsPaymentPageValidated);
  const locale = useSelector(selectDazzlerLocale);
  const selectedConcessions = useSelector(selectSelectedFaBConcessions);
  const selectedGratuity = useSelector(selectSelectedGratuity);
  const showPaymentForm = useSelector(selectShowPaymentForm);

  const isReadyForPayment = isPageValidated && isCustomerReadyForPayment;

  const [showGratuitySelector, setShowGratuitySelector] = useState(
    !!(selectedGratuity.amount > 0 || selectedGratuity.percentage > 0)
  );

  const shouldHideForFandBItemsCostOnly =
    widget?.shape?.gratuityType === PERCENTAGE_OF_F_AND_B_ITEMS_COST_ONLY &&
    selectedConcessions?.list.length === 0;

  useEffect(() => {
    if (error.peachCode === PEACH_CODES.gratuityNotAccepted) {
      setShowGratuitySelector(false);
      dispatch(actionCreators.setSelectedGratuity(false, 0, 0));
    }
  }, [dispatch, error]);

  useEffect(() => {
    !showGratuitySelector &&
      dispatch(actionCreators.setSelectedGratuity(false, 0, 0));
  }, [dispatch, showGratuitySelector]);

  if (!isReadyForPayment || !showPaymentForm || shouldHideForFandBItemsCostOnly)
    return null;

  return (
    <ContainedRow>
      <BorderedCollapse
        closeButtonText={formatMessage(globalMessages.closeButtonText)}
        heading={resolveLocalisedStringOrDefault(
          formatMessage(messages.gratuityTitle),
          locale,
          widget.shape?.gratuityTitle
        )}
        setShow={setShowGratuitySelector}
        show={showGratuitySelector}
      >
        <RichText
          text={resolveLocalisedStringOrDefault(
            formatMessage(messages.gratuityRichText),
            locale,
            widget.shape?.gratuityRichText
          )}
          styles={{ textAlign: 'left' }}
        />
        <div sx={{ mt: 4 }}>
          <GratuityAmountSelector
            gratuityType={widget.shape?.gratuityType}
            maxAmount={gratuityLimitInCents / 100}
            setAmount={actionCreators.setSelectedGratuity}
            values={widget.shape?.gratuityValues}
          />
        </div>
      </BorderedCollapse>
    </ContainedRow>
  );
};

export default GratuityWidget;
