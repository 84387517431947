/** @jsxImportSource theme-ui */
import React from 'react';

import { useSelector } from 'react-redux';
import { Box } from 'theme-ui';

import { JOURNEY_TYPES } from '../../../constants';
import { useValidateJourney } from '../../../hooks/useValidateJourney';
import { getStepNumberForDisplay } from '../../../services/JourneyService';
import {
  selectContent,
  selectJourneyTypeConfig,
  selectKioskSubStep,
  selectStep,
} from '../../../store/Selectors';
import EGiftCardWrapper from '../../common/kiosk/EGiftCardWrapper';
import FandBWrapper from '../../common/kiosk/FandBWrapper';
import ContainedRow from '../../common/layout/ContainedRow';
import ShowtimeHeroConcessions from '../../widgets/showtimehero/ShowtimeHeroConcessions';

const Kiosk: React.FC = () => {
  const content = useSelector(selectContent);
  const journeyTypeConfig = useSelector(selectJourneyTypeConfig);
  const step = useSelector(selectStep);
  const kioskSubStep = useSelector(selectKioskSubStep);

  useValidateJourney();

  const showShowtimeHero =
    journeyTypeConfig.type === JOURNEY_TYPES.CONCESSIONS_ONLY;

  if (!content) return null;

  return (
    <div className='kiosk' data-testid='kiosk'>
      <ContainedRow>
        <h1 sx={{ textAlign: 'center' }}>
          {getStepNumberForDisplay(step)}
          {kioskSubStep === 'egc'
            ? content.kiosk.giftCardUpsellTitle
            : content.kiosk.title}
        </h1>
        {showShowtimeHero && <ShowtimeHeroConcessions />}
      </ContainedRow>

      {kioskSubStep === 'fab-landing' && (
        <FandBWrapper isCategoryLanding={true} />
      )}

      {kioskSubStep === 'fab' && <FandBWrapper />}
      {kioskSubStep === 'egc' && (
        <Box className='giftcards' sx={{ mt: 6 }}>
          <EGiftCardWrapper />
        </Box>
      )}
    </div>
  );
};

export default Kiosk;
