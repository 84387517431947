export const SET_LOADING = 'SET_LOADING';
export const CLEAR_SESSION = 'CLEAR_SESSION';
export const INITIALIZE_SESSION = 'INITIALIZE_SESSION';
export const SET_TOKEN = 'SET_TOKEN';
export const SET_REQUEST_DATA = 'SET_REQUEST_DATA';
export const SET_CIRCUIT_CONFIG = 'SET_CIRCUIT_CONFIG';
export const SET_CIRCUIT_CONTENT = 'SET_CIRCUIT_CONTENT';
export const SET_TICKET_TYPES = 'SET_TICKET_TYPES';
export const SET_HAS_THIRD_PARTY_MEMBER_TICKETS =
  'SET_HAS_THIRD_PARTY_MEMBER_TICKETS';
export const SET_THIRD_PARTY_MEMBERSHIP_NAMES =
  'SET_THIRD_PARTY_MEMBERSHIP_NAMES';
export const SET_AVAILABLE_TICKETS = 'SET_AVAILABLE_TICKETS';
export const ADD_VOUCHER_TICKETS = 'ADD_VOUCHER_TICKETS';
export const SET_SELECTED_DONATION = 'SET_SELECTED_DONATION';
export const SET_DONATION = 'SET_DONATION';
export const SET_DONATION_ROUNDUP = 'SET_DONATION_ROUNDUP';
export const SET_SELECTED_GRATUITY = 'SET_SELECTED_GRATUITY';
export const SET_LOYALTY_REDEMPTION_REWARDS = 'SET_LOYALTY_REDEMPTION_REWARDS';
export const SET_LOYALTY_RECOGNITION_NUMBER = 'SET_LOYALTY_RECOGNITION_NUMBER';
export const SET_BOOKING_FEE_AND_TAX = 'SET_BOOKING_FEE_AND_TAX';
export const SET_BOOKING_FEE = 'SET_BOOKING_FEE';
export const SET_BOOKING_FEE_STRATEGY = 'SET_BOOKING_FEE_STRATEGY';
export const SET_SEATS_MODEL = 'SET_SEATS_MODEL';
export const SET_SINGLE_SEAT_RULE_FIRED = 'SET_SINGLE_SEAT_RULE_FIRED';
export const SET_DEALS = 'SET_DEALS';
export const SET_EMBARGO_DATA = 'SET_EMBARGO_DATA';
export const SET_ERROR = 'SET_ERROR';
export const CLEAR_ERROR = 'CLEAR_ERROR';
export const ADD_SEAT = 'ADD_SEAT';
export const REMOVE_SEAT = 'REMOVE_SEAT';
export const REMOVE_ALL_SEATS = 'REMOVE_ALL_SEATS';
export const SET_SELECTED_SEATS = 'SET_SELECTED_SEATS';
export const SET_CONFIRMATION = 'SET_CONFIRMATION';
export const SET_CUSTOMER_DETAILS = 'SET_CUSTOMER_DETAILS';
export const SET_PAYMENT_DETAILS = 'SET_PAYMENT_DETAILS';
export const SET_HOSTED_PAYMENT = 'SET_HOSTED_PAYMENT';
export const REMOVE_HOSTED_PAYMENT = 'REMOVE_HOSTED_PAYMENT';
export const ADD_GIFTCARD = 'ADD_GIFTCARD';
export const REMOVE_GIFTCARD = 'REMOVE_GIFTCARD';
export const REMOVE_ALL_GIFTCARDS = 'REMOVE_ALL_GIFTCARDS';
export const SET_GIFTCARD_RECIPIENT = 'SET_GIFTCARD_RECIPIENT';
export const SET_VOUCHER_RECIPIENT = 'SET_VOUCHER_RECIPIENT';
export const SET_PHYSICAL_GIFTCARD_RECIPIENT =
  'SET_PHYSICAL_GIFTCARD_RECIPIENT';
export const SET_PHYSICAL_GIFTCARD_BILLING = 'SET_PHYSICAL_GIFTCARD_BILLING';
export const SET_COUNTDOWN = 'SET_COUNTDOWN';
export const SET_COUNTDOWN_EXPIRED = 'SET_COUNTDOWN_EXPIRED';
export const SET_SEND_ANALYTICS = 'SET_SEND_ANALYTICS';
export const SET_QUERY_STRING = 'SET_QUERY_STRING';
export const SET_STEP = 'SET_STEP';
export const SET_KIOSK_SUBSTEP = 'SET_KIOSK_SUBSTEP';
export const SET_KIOSK_ACTIVE_CATEGORY = 'SET_KIOSK_ACTIVE_CATEGORY';
export const SET_JOURNEY_TYPE = 'SET_JOURNEY_TYPE';
export const SET_REFUND = 'SET_REFUND';
export const SET_CONCESSIONS = 'SET_CONCESSIONS';
export const ADD_CONCESSION = 'ADD_CONCESSION';
export const REMOVE_CONCESSION = 'REMOVE_CONCESSION';
export const CHANGE_CONCESSION_BY_INDEX = 'CHANGE_VARIABLE_CONCESSION_BY_INDEX';
export const CHANGE_GIFTCARD_BY_ID = 'CHANGE_GIFTCARD_BY_ID';
export const CHANGE_DELIVERY_ITEM = 'CHANGE_DELIVERY_ITEM';
export const CHANGE_DELIVERY_ITEM_ID = 'CHANGE_DELIVERY_ITEM_ID';
export const CLEAR_CONCESSIONS = 'CLEAR_CONCESSIONS';
export const CLEAR_AVAILABLE_CONCESSIONS = 'CLEAR_AVAILABLE_CONCESSIONS';
export const SET_CONCESSIONS_ADDED = 'SET_CONCESSIONS_ADDED';
export const SET_GIFTCARDS_ADDED = 'SET_GIFTCARDS_ADDED';
export const SET_LANGUAGE = 'SET_LANGUAGE';
export const SET_SHOWTIMES = 'SET_SHOWTIMES';
export const SET_ALLOW_PAYMENT = 'SET_ALLOW_PAYMENT';
export const SET_CONCESSIONS_JOURNEY_PARENT_BOOKING_REF =
  'SET_CONCESSIONS_JOURNEY_PARENT_BOOKING_REF';
export const SET_DELIVERY_PREFERENCE = 'SET_DELIVERY_PREFERENCE';
export const SET_SELECTED_DELIVERY_WINDOW = 'SET_SELECTED_DELIVERY_WINDOW';
export const SET_DELIVERY_WINDOW = 'SET_DELIVERY_WINDOW';
export const SET_DELIVERY_WINDOWS = 'SET_DELIVERY_WINDOWS';
export const SET_GUEST_MARKETING = 'SET_GUEST_MARKETING';
export const SET_GUEST_NEWSLETTER_SIGN_UP = 'SET_GUEST_NEWSLETTER_SIGN_UP';
export const SET_GUEST_SESSION_TOKEN = 'SET_GUEST_SESSION_TOKEN';
export const SET_DATALAYER_POPULATED = 'SET_DATALAYER_POPULATED';
export const SET_HAS_VOUCHER_APPLIED = 'SET_HAS_VOUCHER_APPLIED';
export const SET_GRAND_TOTAL_WITH_DISCOUNT = 'SET_GRAND_TOTAL_WITH_DISCOUNT';
export const SET_DEALS_IN_TICKETS_STEP = 'SET_DEALS_IN_TICKETS_STEP';
export const SET_APPLIED_DEALS_WITH_DISCOUNT =
  'SET_APPLIED_DEALS_WITH_DISCOUNT';
export const SET_TOTAL_DISCOUNT = 'SET_TOTAL_DISCOUNT';
export const ADD_VOUCHER = 'ADD_VOUCHER';
export const REMOVE_VOUCHER = 'REMOVE_VOUCHER';
export const SET_LOYALTY_TIER_SELECTION = 'SET_LOYALTY_TIER_SELECTION';
export const SET_HOSTED_PAYMENT_IN_PROGRESS = 'SET_HOSTED_PAYMENT_IN_PROGRESS';
export const SET_CONSENT = 'SET_CONSENT';
export const INITIALIZE_AMAZONPAY_PAYMENT = 'INITIALIZE_AMAZONPAY_PAYMENT';
export const INITIALIZE_AMAZONPAYV2_PAYMENT = 'INITIALIZE_AMAZONPAYV2_PAYMENT';
export const SET_SELECTED_PAYMENT_PROVIDER = 'SET_SELECTED_PAYMENT_PROVIDER';
export const SUBMIT_MAKE_PAYMENT_REQUEST = 'SUBMIT_MAKE_PAYMENT_REQUEST';
export const SET_TERMS_AND_CONDITIONS_CHECKED =
  'SET_TERMS_AND_CONDITIONS_CHECKED';
export const SET_CART_SUMMARY_OPEN = 'SET_CART_SUMMARY_OPEN';
export const SET_CART_SUMMARY = 'SET_CART_SUMMARY';
export const ADYEN_INITIATE_PAYMENT = 'ADYEN_INITIATE_PAYMENT';
export const ADYEN_SUBMIT_ADDITIONAL_DETAILS =
  'ADYEN_SUBMIT_ADDITIONAL_DETAILS';
export const SPREEDLY_INITIATE_PAYMENT = 'SPREEDLY_INITIATE_PAYMENT';
export const SPREEDLY_COMPLETE3DSECURE = 'SPREEDLY_COMPLETE3DSECURE';
export const ADD_CEA_CARD = 'ADD_CEA_CARD';
export const REMOVE_CEA_CARD = 'REMOVE_CEA_CARD';
export const CLEAR_CEA_CARDS = 'CLEAR_CEA_CARDS';
export const ADD_SINGLE_TICKET = 'ADD_SINGLE_TICKET';
export const REMOVE_SINGLE_TICKET = 'REMOVE_SINGLE_TICKET';
export const VALIDATE_DYNAMIC_BASKET = 'VALIDATE_DYNAMIC_BASKET';
export const VALIDATE_CEA_CARD = 'VALIDATE_CEA_CARD';
export const ADD_BASKET_TICKETS = 'ADD_BASKET_TICKETS';
export const ADD_MEMBER_CARD = 'ADD_MEMBER_CARD';
export const REMOVE_MEMBER_CARD = 'REMOVE_MEMBER_CARD';
export const CLEAR_MEMBER_CARDS = 'CLEAR_MEMBER_CARDS';
export const SET_TICKET_CREDITS = 'SET_TICKET_CREDITS';
export const SET_TICKET_CREDITS_AVAILABLE = 'SET_TICKET_CREDITS_AVAILABLE';
export const HOSTED_PAYMENT_REQUEST = 'HOSTED_PAYMENT_REQUEST';
export const ADYEN_DROPIN_HOSTED_PAYMENT = 'ADYEN_DROPIN_HOSTED_PAYMENT';
export const SET_DELIVERY_OPTION_BASED_ON_SEATING_AREA =
  'SET_DELIVERY_OPTION_BASED_ON_SEATING_AREA';
export const REMOVE_UNCOLLECTIBLE_CONCESSIONS =
  'REMOVE_UNCOLLECTIBLE_CONCESSIONS';
export const SET_SELECTED_SEASON_PASS = 'SET_SELECTED_SEASON_PASS';
export const SET_SEASON_PASS_MOVIES = 'SET_SEASON_PASS_MOVIES';
export const SET_SELECTED_SEASON_PASS_SESSIONS =
  'SET_SELECTED_SEASON_PASS_SESSIONS';
export const REMOVE_SELECTED_SEASON_PASS_SESSION =
  'REMOVE_SELECTED_SEASON_PASS_SESSION';
export const ADD_CARD_PAYMENT_PROMO_BANK_CARD =
  'ADD_CARD_PAYMENT_PROMO_BANK_CARD';
export const REMOVE_CARD_PAYMENT_PROMO_BANK_CARD =
  'REMOVE_CARD_PAYMENT_PROMO_BANK_CARD';
export const SET_DIDOMI_READY = 'SET_DIDOMI_READY';
export const SET_F_AND_B_ITEM_MODAL_DATA = 'SET_F_AND_B_ITEM_MODAL_DATA';
export const REMOVE_F_AND_B_ITEM_MODAL_DATA = 'REMOVE_F_AND_B_ITEM_MODAL_DATA';
export const SET_REMOVE_CONCESSION_MODAL_DATA =
  'SET_REMOVE_CONCESSION_MODAL_DATA';
export const REMOVE_REMOVE_CONCESSION_MODAL_DATA =
  'REMOVE_REMOVE_CONCESSION_MODAL_DATA';
export const SET_ORDER_EXISTS = 'SET_ORDER_EXISTS';
export const SET_SELECTED_LANGUAGE_CULTURE = 'SET_SELECTED_LANGUAGE_CULTURE';
export const SET_IS_PAYMENT_PAGE_VALIDATED = 'SET_IS_PAYMENT_PAGE_VALIDATED';
export const SET_SHOW_PAYMENT_FORM = 'SET_SHOW_PAYMENT_FORM';
export const SET_SHOW_GIFT_CARD_FORM = 'SET_SHOW_GIFT_CARD_FORM';
export const SET_DAZZLER = 'SET_DAZZLER';
export const SET_DAZZLER_CONTENT = 'SET_DAZZLER_CONTENT';
export const SET_DAZZLER_STEPS = 'SET_DAZZLER_STEPS';
export const SET_SHOW_TERMS_AND_CONDITIONS_CHECKBOX =
  'SET_SHOW_TERMS_AND_CONDITIONS_CHECKBOX';
