import React, { FC, memo } from 'react';

import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import messages from './intl';
import RtsLoyaltyRecognition from './RtsLoyaltyRecognition';

import {
  LoyaltyCardNumberEntryConfig,
  LoyaltyCardNumberEntryContent,
} from '../../../@types/modelTypes';
import {
  selectBookingData,
  selectDazzlerLocale,
  selectIsCustomerReadyForPayment,
  selectIsPaymentPageValidated,
} from '../../../store/Selectors';
import { resolveLocalisedStringOrDefault } from '../helpers';
import globalMessages from '../intl';
import { WidgetData } from '../types';
type Props = {
  widget: WidgetData<'TicketingCMSJourneyLoyaltyCardNumberEntryWidget'>;
};

export const LoyaltyCardNumberEntry: FC<Props> = ({ widget }) => {
  const { formatMessage } = useIntl();
  const locale = useSelector(selectDazzlerLocale);
  const bookingData = useSelector(selectBookingData);
  const isPageValidated = useSelector(selectIsPaymentPageValidated);
  const isCustomerReadyForPayment = useSelector(
    selectIsCustomerReadyForPayment
  );

  const isReadyForPayment = isPageValidated && isCustomerReadyForPayment;

  const loyaltyCardNumberEntryConfig: LoyaltyCardNumberEntryConfig = {
    enableTicketCreditRedemption: !!widget.shape?.enableTicketCreditRedemption,
  };

  const loyaltyCardNumberEntryContent: LoyaltyCardNumberEntryContent = {
    loyaltyRecognitionSubtitle: resolveLocalisedStringOrDefault(
      formatMessage(messages.loyaltyRecognitionSubtitle),
      locale,
      widget.shape?.loyaltyRecognitionSubtitle
    ),
    loyaltyRecognitionHeading: resolveLocalisedStringOrDefault(
      formatMessage(messages.loyaltyRecognitionHeading),
      locale,
      widget.shape?.loyaltyRecognitionHeading
    ),
    loyaltyRecognitionLabel: resolveLocalisedStringOrDefault(
      formatMessage(messages.loyaltyRecognitionLabel),
      locale,
      widget.shape?.loyaltyRecognitionLabel
    ),
    loyaltyRecognitionPlaceHolder: resolveLocalisedStringOrDefault(
      formatMessage(messages.loyaltyRecognitionPlaceHolder),
      locale,
      widget.shape?.loyaltyRecognitionPlaceHolder
    ),
    loyaltyRecognitionErrorText: resolveLocalisedStringOrDefault(
      formatMessage(messages.loyaltyRecognitionErrorText),
      locale,
      widget.shape?.loyaltyRecognitionErrorText
    ),
    loyaltyRecognitionSuccessText: resolveLocalisedStringOrDefault(
      formatMessage(messages.loyaltyRecognitionSuccessText),
      locale,
      widget.shape?.loyaltyRecognitionSuccessText
    ),
    loyaltyRecognitionTicketCreditLabel: resolveLocalisedStringOrDefault(
      formatMessage(messages.loyaltyRecognitionTicketCreditLabel),
      locale,
      widget.shape?.loyaltyRecognitionTicketCreditLabel
    ),
    loyaltyRecognitionTicketCreditsAvailableLabel:
      resolveLocalisedStringOrDefault(
        formatMessage(messages.loyaltyRecognitionTicketCreditsAvailableLabel),
        locale,
        widget.shape?.loyaltyRecognitionTicketCreditsAvailableLabel
      ),
    loyaltyRecognitionTicketCreditsRichText: resolveLocalisedStringOrDefault(
      formatMessage(messages.loyaltyRecognitionTicketCreditsRichText),
      locale,
      widget.shape?.loyaltyRecognitionTicketCreditsRichText
    ),
    closeButtonText: formatMessage(globalMessages.closeButtonText),
    submitButtonText: formatMessage(globalMessages.submitButtonText),
    loyaltyNumberErrorRichText: formatMessage(
      messages.loyaltyNumberErrorRichText
    ),
  };

  if (!bookingData?.isLoyaltyEarnPointsEnabled || !isReadyForPayment) return;

  return (
    <RtsLoyaltyRecognition
      loyaltyCardNumberEntryContent={loyaltyCardNumberEntryContent}
      loyaltyCardNumberEntryConfig={loyaltyCardNumberEntryConfig}
    />
  );
};

export default memo(LoyaltyCardNumberEntry);
