/** @jsxImportSource theme-ui */
import React from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { Box, Flex } from 'theme-ui';

import { QuantitySelectorContext } from '../../../@types/actionTypes';
import {
  LoyaltyCardNumberEntryContent,
  TicketCredit,
} from '../../../@types/modelTypes';
import { getTotalNumberActualTickets } from '../../../services/Helpers';
import { actionCreators } from '../../../store/ActionCreators';
import {
  selectTicketCredits,
  selectTicketCreditsAvailable,
  selectTicketTypes,
} from '../../../store/Selectors';
import QuantityButton from '../../common/quantitybutton/QuantityButton';
import RichText from '../../common/richtext/RichText';
const priceDescending = (a: TicketCredit, b: TicketCredit) => b.price - a.price;
const priceAscending = (a: TicketCredit, b: TicketCredit) => a.price - b.price;
const sumCreditsUsed = (a: number, b: TicketCredit) => a + b.used;
interface Props {
  loyaltyCardNumberEntryContent: LoyaltyCardNumberEntryContent;
}
const RtsTicketCreditsRedemption: React.FC<Props> = ({
  loyaltyCardNumberEntryContent,
}) => {
  const dispatch = useDispatch();

  const selectedTickets = useSelector(selectTicketTypes);
  const ticketCredits = useSelector(selectTicketCredits);
  const ticketCreditsAvailable = useSelector(selectTicketCreditsAvailable);

  const creditsUsed = ticketCredits?.reduce(sumCreditsUsed, 0) ?? 0;

  const countOfActualTickets = selectedTickets
    ? getTotalNumberActualTickets(selectedTickets.ticketTypeModels)
    : 0;

  const handleApplyTicketCredits = (context: QuantitySelectorContext) => {
    if (!ticketCredits) return;
    const credits = [...ticketCredits];
    if (context === 'add') {
      const creditToUpdate = credits
        .toSorted(priceDescending)
        .find((x: TicketCredit) => x.used < x.max);
      if (creditToUpdate) creditToUpdate.used += 1;
      dispatch(actionCreators.setTicketCredits(credits));
    } else {
      const creditToUpdate = credits
        .toSorted(priceAscending)
        .find((x: TicketCredit) => x.used > 0);
      if (creditToUpdate) creditToUpdate.used -= 1;
      dispatch(actionCreators.setTicketCredits(credits));
    }
  };
  if (!ticketCreditsAvailable) return null;
  return (
    <Box mt={4}>
      <p
        sx={{
          mb: 4,
          textAlign: 'center',
          textTransform: 'uppercase',
          fontWeight: 'bold',
        }}
      >
        {ticketCreditsAvailable}{' '}
        {
          loyaltyCardNumberEntryContent.loyaltyRecognitionTicketCreditsAvailableLabel
        }
      </p>
      <RichText
        text={
          loyaltyCardNumberEntryContent.loyaltyRecognitionTicketCreditsRichText
        }
      />
      <Flex
        className='border-y-solid'
        sx={{ mt: 2, p: 4, alignItems: 'center', justifyContent: 'center' }}
      >
        <QuantityButton
          context='remove'
          disabled={creditsUsed <= 0}
          onClick={() => handleApplyTicketCredits('remove')}
        />
        <div sx={{ flexGrow: 1, textAlign: 'center' }}>
          {loyaltyCardNumberEntryContent.loyaltyRecognitionTicketCreditLabel}{' '}
          <span sx={{ fontWeight: 'bold' }}>x{creditsUsed}</span>
        </div>
        <QuantityButton
          context='add'
          disabled={
            creditsUsed >= ticketCreditsAvailable ||
            creditsUsed >= countOfActualTickets
          }
          onClick={() => handleApplyTicketCredits('add')}
        />
      </Flex>
    </Box>
  );
};

export default RtsTicketCreditsRedemption;
