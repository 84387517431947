/** @jsxImportSource theme-ui */
import React, { useEffect, useState } from 'react';

import { Collapse } from 'react-bootstrap';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Flex } from 'theme-ui';

import { DonationContent, GlobalState } from '../../../../@types/modelTypes';
import { PEACH_CODES } from '../../../../constants';
import { actionCreators } from '../../../../store/ActionCreators';
import {
  selectContent,
  selectError,
  selectSelectedGratuity,
} from '../../../../store/Selectors';
import { resolveTicketingCMSStringOrDefault } from '../../../widgets/helpers';
import globalMessages from '../../../widgets/intl';
import AmountSelector from '../../amountselector/AmountSelector';
import CheckBoxButton from '../../checkboxbutton/CheckBoxButton';
import ContainedRow from '../../layout/ContainedRow';
import RichText from '../../richtext/RichText';

const Gratuity: React.FC = () => {
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();

  const configPayment = useSelector(
    (state: GlobalState) => state.config.payment
  );
  const content = useSelector(selectContent);
  const error = useSelector(selectError);
  const gratuityLimitInCents = useSelector(
    (state: GlobalState) => state.gratuityLimitInCents
  );
  const selectedGratuity = useSelector(selectSelectedGratuity);

  const [showGratuitySelector, setShowGratuitySelector] = useState(
    selectedGratuity.amount > 0 || selectedGratuity.percentage > 0
      ? true
      : false
  );
  const gratuityValues = configPayment.gratuityValues?.map(function (el) {
    return el.amount;
  });
  useEffect(() => {
    if (error.peachCode === PEACH_CODES.gratuityNotAccepted) {
      setShowGratuitySelector(false);
      dispatch(actionCreators.setSelectedGratuity(false, 0, 0));
    }
  }, [dispatch, error]);

  useEffect(() => {
    !showGratuitySelector &&
      dispatch(actionCreators.setSelectedGratuity(false, 0, 0));
  }, [dispatch, showGratuitySelector]);

  const amountContent: DonationContent = {
    amountSelectorCustomAmountLabel: resolveTicketingCMSStringOrDefault(
      formatMessage(globalMessages.amountSelectorCustomAmountLabel),
      content.amountSelectorCustomAmountLabel
    ),
    amountSelectorSelectedText: resolveTicketingCMSStringOrDefault(
      formatMessage(globalMessages.amountSelectorSelectedText),
      content.amountSelectorSelectedText
    ),
    amountSelectorCustomAmountTooltipText: resolveTicketingCMSStringOrDefault(
      formatMessage(globalMessages.amountSelectorCustomAmountTooltipText),
      content.amountSelectorCustomAmountTooltipText
    ),
  };
  return (
    <Box className='gratuity' mt={6}>
      <ContainedRow>
        <div className='bordered'>
          <h3>{content.payment.gratuityTitle}</h3>
          <Box
            className='gratuity-toggle-button'
            sx={{
              borderTop: 'boxSeparatorDashed',
              mt: 4,
              pt: 4,
            }}
          >
            <Flex sx={{ alignItems: 'center' }}>
              <CheckBoxButton
                checked={showGratuitySelector}
                onClick={() => setShowGratuitySelector(!showGratuitySelector)}
              />
              <span>{content.payment.gratuityAddText}</span>
            </Flex>
          </Box>
          <Collapse in={showGratuitySelector}>
            <div>
              <div
                sx={{
                  borderTop: 'boxSeparatorDashed',
                  mt: 4,
                  pt: 4,
                }}
              >
                <h2>{content.payment.gratuityHeading}</h2>
                <RichText
                  text={content.payment.gratuityRichText}
                  styles={{ textAlign: 'left' }}
                />
                <div sx={{ mt: 5 }}>
                  <AmountSelector
                    isPercentage={configPayment.gratuityIsPercentage}
                    maxAmount={gratuityLimitInCents / 100}
                    setAmount={actionCreators.setSelectedGratuity}
                    values={gratuityValues}
                    variant={'gratuity'}
                    amountContent={amountContent}
                  />
                </div>
              </div>
            </div>
          </Collapse>
        </div>
      </ContainedRow>
    </Box>
  );
};

export default Gratuity;
