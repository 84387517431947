import { defineMessages } from 'react-intl';

export default defineMessages({
  thirdPartyVoucherEntryHeading: {
    id: 'third-party-voucher-entry.widget-heading',
    defaultMessage: 'Add voucher',
  },
  thirdPartyVoucherEntryLabel: {
    id: 'third-party-voucher-entry.input-label',
    defaultMessage: 'Enter voucher code here',
  },
  thirdPartyVoucherEntryPlaceholder: {
    id: 'third-party-voucher-entry.input-placeholder',
    defaultMessage: 'Enter voucher code here',
  },
  thirdPartyVoucherEntryErrorMessage: {
    id: 'third-party-voucher-entry.error-message-text',
    defaultMessage: 'Error',
  },
  thirdPartyVoucherEntryAlreadyAppliedMessage: {
    id: 'third-party-voucher-entry.already-applied-message-text',
    defaultMessage: 'Voucher already applied',
  },
  thirdPartyVoucherEntryCardSuspendedMessage: {
    id: 'third-party-voucher-entry.card-suspended-message-text',
    defaultMessage:
      'This card has been suspended. Please contact them for more information.',
  },
  thirdPartyVoucherEntryCardExpiredMessage: {
    id: 'third-party-voucher-entry.card-expired-message-text',
    defaultMessage: 'This card has expired, please renew it.',
  },
});
