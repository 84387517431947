import { ContentSeats } from '../@types/contentTypes';
import { SeatCustomStyle, SeatMapSeat } from '../@types/modelTypes';
import { SEAT_STATUS, SEAT_STYLE, SEAT_TYPE } from '../constants';

export const seatStatuses = [
  { status: SEAT_STATUS.AVAILABLE, cssClass: 'available' },
  { status: SEAT_STATUS.UNAVAILABLE, cssClass: 'unavailable' },
  { status: SEAT_STATUS.SELECTED, cssClass: 'selected' },
  { status: SEAT_STATUS.BROKEN, cssClass: 'unavailable' },
  { status: SEAT_STATUS.BUFFER, cssClass: 'buffer' },
  { status: SEAT_STATUS.WHITESPACE, cssClass: '' },
];

export const getSeatStatusConfig = (seat: SeatMapSeat, isSelected: boolean) => {
  let status = isSelected
    ? SEAT_STATUS.SELECTED
    : seat.isPreSelected
    ? SEAT_STATUS.AVAILABLE
    : seat.status; // return natural status

  if (!seat.seatName) {
    status = SEAT_STATUS.WHITESPACE;
  }
  return seatStatuses.find((s) => s.status === status);
};

export const getSeatButtonTitle = (
  seatsContent: ContentSeats,
  seat: SeatMapSeat,
  status: number | undefined,
  seatCustomStyle: SeatCustomStyle | undefined,
  selectedLanguageCulture: string,
  areaProhibited: boolean,
  disableWheelchairSelection: boolean
) => {
  const getSeatStatusTitle = () => {
    if (areaProhibited || disableWheelchairSelection)
      return seatsContent.titleUnavailableText;
    switch (status) {
      case SEAT_STATUS.AVAILABLE:
        return seatsContent.titleAvailableText;
      case SEAT_STATUS.UNAVAILABLE:
        return seatsContent.titleUnavailableText;
      case SEAT_STATUS.BUFFER:
        return seatsContent.titleBufferText;
      case SEAT_STATUS.SELECTED:
        return seatsContent.titleSelectedText;
      case SEAT_STATUS.BROKEN:
        return seatsContent.titleUnavailableText;
      default:
        return '';
    }
  };

  const getSeatTypeTextFromStyle = () => {
    let customTitle;
    if (seatCustomStyle?.data?.content) {
      customTitle =
        seatCustomStyle.data.content[selectedLanguageCulture]['title'];
    }
    if (customTitle) {
      return customTitle;
    }

    switch (seat.style) {
      case SEAT_STYLE.SOFA_LEFT:
        return seatsContent.titleLeftSofaText;
      case SEAT_STYLE.SOFA_MIDDLE:
        return seatsContent.titleMiddleSofaText;
      case SEAT_STYLE.SOFA_RIGHT:
        return seatsContent.titleRightSofaText;
      default:
        switch (seat.type) {
          case SEAT_TYPE.WHEELCHAIR:
            return seatsContent.titleAccessibleText;
          case SEAT_TYPE.COMPANION:
            return seatsContent.titleCompanionText;
          case SEAT_TYPE.VIP:
            return seatsContent.titleVipText;
          case SEAT_TYPE.LUXURY_RECLINER:
            return seatsContent.titleLuxuryReclinerText;
          default:
            return '';
        }
    }
  };

  if (seat.type == SEAT_TYPE.NON_BOOKABLE_ELEMENT) return '';

  let seatTypeText = getSeatTypeTextFromStyle();
  let titleSeatText = seatsContent.titleSeatText;
  const seatStatusText = getSeatStatusTitle();

  if (seatTypeText) {
    seatTypeText = `${' '}${seatTypeText}`;
  }
  if (titleSeatText) {
    titleSeatText = `${' '}${titleSeatText}`;
  }

  return `${seatStatusText}${seatTypeText}${titleSeatText}${', '}${
    seatsContent.titleRowText
  }${' '}${seat.rowPhysicalName}${', '}${
    seatsContent.titleSeatNumberText
  }${' '}${seat.text}`;
};

export const getSeatTypeConfigs = (isCoordinateBased: boolean) => {
  return [
    {
      type: SEAT_TYPE.NORMAL,
      style: SEAT_STYLE.NORMAL,
      svgClass: '',
      svgSrc: isCoordinateBased ? 'CoordinateSeatSvg' : 'SeatSvg',
      shouldShowSeatId: true,
    },
    {
      type: SEAT_TYPE.LUXURY_RECLINER,
      style: SEAT_STYLE.NORMAL,
      svgClass: 'recliner',
      svgSrc: 'SeatLuxurySvg',
      shouldShowSeatId: true,
    },
    {
      type: SEAT_TYPE.OBSTRUCTED_VIEW,
      style: SEAT_STYLE.NORMAL,
      svgClass: 'with-icon',
      svgSrc: 'ObstructedSvg',
      shouldShowSeatId: false,
    },
    {
      type: SEAT_TYPE.WHEELCHAIR,
      style: SEAT_STYLE.NORMAL,
      svgClass: 'with-icon',
      svgSrc: 'WheelchairSvg',
      shouldShowSeatId: false,
    },
    {
      type: SEAT_TYPE.COMPANION,
      style: SEAT_STYLE.NORMAL,
      svgClass: 'with-icon',
      svgSrc: 'CompanionSvg',
      shouldShowSeatId: false,
    },
    {
      type: SEAT_TYPE.VIP,
      style: SEAT_STYLE.NORMAL,
      svgClass: '',
      svgSrc: isCoordinateBased ? 'CoordinateSeatSvg' : 'SeatSvg',
      shouldShowSeatId: true,
    },
    {
      type: SEAT_TYPE.SOFA_SEAT,
      style: SEAT_STYLE.SOFA_LEFT,
      svgClass: '',
      svgSrc: 'SofaLeftSvg',
      shouldShowSeatId: true,
    },
    {
      type: SEAT_TYPE.SOFA_SEAT,
      style: SEAT_STYLE.SOFA_MIDDLE,
      svgClass: '',
      svgSrc: 'SofaMiddleSvg',
      shouldShowSeatId: true,
    },
    {
      type: SEAT_TYPE.SOFA_SEAT,
      style: SEAT_STYLE.SOFA_RIGHT,
      svgClass: '',
      svgSrc: 'SofaRightSvg',
      shouldShowSeatId: true,
    },
    {
      type: SEAT_TYPE.NON_BOOKABLE_ELEMENT,
      style: SEAT_STYLE.ENTRANCE_LEFT,
      svgClass: '',
      svgSrc: 'EntranceLeftSvg',
      shouldShowSeatId: false,
    },
    {
      type: SEAT_TYPE.NON_BOOKABLE_ELEMENT,
      style: SEAT_STYLE.ENTRANCE_RIGHT,
      svgClass: '',
      svgSrc: 'EntranceRightSvg',
      shouldShowSeatId: false,
    },
    {
      type: SEAT_TYPE.NON_BOOKABLE_ELEMENT,
      style: SEAT_STYLE.EMERGENCY_EXIT,
      svgClass: '',
      svgSrc: 'EmergencyExitSvg',
      shouldShowSeatId: false,
    },
    {
      type: SEAT_TYPE.CUSTOM_STYLE,
      style: SEAT_STYLE.NORMAL,
      svgClass: 'with-icon',
      svgSrc: isCoordinateBased ? 'CoordinateSeatSvg' : 'SeatSvg',
      shouldShowSeatId: true,
    },
    {
      type: SEAT_TYPE.CUSTOM_STYLE,
      style: SEAT_STYLE.SOFA_LEFT,
      svgClass: '',
      svgSrc: 'SofaLeftSvg',
      shouldShowSeatId: true,
    },
    {
      type: SEAT_TYPE.CUSTOM_STYLE,
      style: SEAT_STYLE.SOFA_MIDDLE,
      svgClass: '',
      svgSrc: 'SofaMiddleSvg',
      shouldShowSeatId: true,
    },
    {
      type: SEAT_TYPE.CUSTOM_STYLE,
      style: SEAT_STYLE.SOFA_RIGHT,
      svgClass: '',
      svgSrc: 'SofaRightSvg',
      shouldShowSeatId: true,
    },
    {
      type: SEAT_TYPE.NON_BOOKABLE_ELEMENT,
      style: SEAT_STYLE.STAIRS_DOWN,
      svgClass: '',
      svgSrc: 'StairsDown',
      shouldShowSeatId: false,
    },
    {
      type: SEAT_TYPE.NON_BOOKABLE_ELEMENT,
      style: SEAT_STYLE.STAIRS_UP,
      svgClass: '',
      svgSrc: 'StairsUp',
      shouldShowSeatId: false,
    },
  ];
};
