/** @jsxImportSource theme-ui */
import React, { useState } from 'react';

import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { Box } from 'theme-ui';

import {
  DonationConfig,
  DonationContent,
  LoyaltyCardNumberEntryConfig,
  LoyaltyCardNumberEntryContent,
} from '../../../@types/modelTypes';
import { TrackingEvent } from '../../../@types/trackingTypes';
import { JOURNEY_TYPES } from '../../../constants';
import useAnalyticsTrackOnce from '../../../hooks/useAnalyticsTrackOnce';
import useConfirmationPageRedirect from '../../../hooks/useConfirmationPageRedirect';
import { useValidateJourney } from '../../../hooks/useValidateJourney';
import { getStepNumberForDisplay } from '../../../services/JourneyService';
import { actionCreators } from '../../../store/ActionCreators';
import {
  isIosApp,
  selectBookingData,
  selectConfig,
  selectConfigDonationValues,
  selectContent,
  selectIsCustomerReadyForPayment,
  selectJourneyTypeConfig,
  selectSelectedGiftCards,
  selectShouldShowGuestMarketingOptions,
  selectStep,
} from '../../../store/Selectors';
import ActionButton from '../../common/actionbutton/ActionButton';
import CustomerDetailsCollapse from '../../common/customerdetails/CustomerDetailsCollapse';
import ContainedRow from '../../common/layout/ContainedRow';
import PaymentDesignWrapper from '../../common/payment/design/PaymentDesignWrapper';
import GiftCardUpsellSummaryRow from '../../common/payment/others/GiftCardUpsellSummaryRow';
import Gratuity from '../../common/payment/others/Gratuity';
import GuestMarketing from '../../common/payment/others/GuestMarketing';
import GuestNewsletterSignUpCheckbox from '../../common/payment/others/GuestNewsletterSignUpCheckbox';
import RoktWidget from '../../common/payment/others/RoktWidget';
import RichText from '../../common/richtext/RichText';
import TermsAndConditions from '../../common/termsandconditions/TermsAndConditions';
import DazzlerWrapper from '../../dazzlercommon/DazzlerWrapper';
import LegacyWrapper from '../../dazzlercommon/LegacyWrapper';
import Donations from '../../widgets/donation/Donations';
import messages from '../../widgets/donation/intl';
import { resolveTicketingCMSStringOrDefault } from '../../widgets/helpers';
import globalMessages from '../../widgets/intl';
import loyaltyRecognitionMessages from '../../widgets/loyaltycardnumberentry/intl';
import RtsLoyaltyRecognition from '../../widgets/loyaltycardnumberentry/RtsLoyaltyRecognition';
import ShowtimeHeroConcessions from '../../widgets/showtimehero/ShowtimeHeroConcessions';
import ShowtimeHeroLegacyWrapper from '../../widgets/showtimehero/ShowtimeHeroLegacyWrapper';
const Payment: React.FC = () => {
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();

  const bookingData = useSelector(selectBookingData);
  const config = useSelector(selectConfig);
  const content = useSelector(selectContent);
  const donationValues = useSelector(selectConfigDonationValues);
  const journeyTypeConfig = useSelector(selectJourneyTypeConfig);
  const selectedGiftCards = useSelector(selectSelectedGiftCards);
  const step = useSelector(selectStep);
  const isCustomerReadyForPayment = useSelector(
    selectIsCustomerReadyForPayment
  );
  const shouldShowGuestMarketingOptions = useSelector(
    selectShouldShowGuestMarketingOptions
  );
  const isIOSApp = useSelector(isIosApp);

  const showNewsLetterSignUp =
    config?.payment.showGuestNewsletterSignUpCheckbox &&
    !bookingData?.isUserValidated;
  const canLaunchPayment =
    isCustomerReadyForPayment &&
    //!config.payment.enableGuestMarketing &&
    !showNewsLetterSignUp;

  const [isPageValidated, setIsPageValidated] = useState(!!canLaunchPayment);
  const [showPaymentForm, setShowPaymentForm] = useState(true);
  const [showGiftCardForm, setShowGiftCardForm] = useState(true);
  const [creditCardType, setCreditCardType] = useState('');
  const isReadyForPayment = isPageValidated && isCustomerReadyForPayment;

  useAnalyticsTrackOnce(TrackingEvent.PAYMENT_LANDING);

  useValidateJourney();

  useConfirmationPageRedirect();

  if (!content || !config || !bookingData) return null;

  const setPaymentFormVisibility = (nextShowPaymentForm: boolean) => {
    setShowPaymentForm(nextShowPaymentForm);
  };

  const setGiftCardFormVisibility = (nextShowGiftCardForm: boolean) => {
    setShowGiftCardForm(nextShowGiftCardForm);
  };

  const handleValidatePage = () => {
    setIsPageValidated(true);
  };

  const resetHostedPayment = () => {
    setIsPageValidated(false);
    dispatch(actionCreators.removeHostedPayments());
  };
  const donationConfig: DonationConfig = {
    enableDonationRoundUpField: config.payment.enableDonationRoundUpField,
    donationValues: donationValues,
  };

  const donationContent: DonationContent = {
    donationSubTitle: resolveTicketingCMSStringOrDefault(
      formatMessage(messages.donationSubTitle),
      content.payment.donationSubTitle
    ),
    donationText: resolveTicketingCMSStringOrDefault(
      formatMessage(messages.donationText),
      content.payment.donationText
    ),
    donationRoundUpRichText: resolveTicketingCMSStringOrDefault(
      formatMessage(messages.donationRoundUpRichText),
      content.payment.donationRoundUpRichText
    ),
    amountSelectorCustomAmountLabel: resolveTicketingCMSStringOrDefault(
      formatMessage(globalMessages.amountSelectorCustomAmountLabel),
      content.amountSelectorCustomAmountLabel
    ),
    amountSelectorSelectedText: resolveTicketingCMSStringOrDefault(
      formatMessage(globalMessages.amountSelectorSelectedText),
      content.amountSelectorSelectedText
    ),
    amountSelectorCustomAmountTooltipText: resolveTicketingCMSStringOrDefault(
      formatMessage(globalMessages.amountSelectorCustomAmountTooltipText),
      content.amountSelectorCustomAmountTooltipText
    ),
  };
  const loyaltyCardNumberEntryConfig: LoyaltyCardNumberEntryConfig = {
    enableTicketCreditRedemption: config.payment.enableTicketCreditRedemption,
  };
  const loyaltyCardNumberEntryContent: LoyaltyCardNumberEntryContent = {
    loyaltyRecognitionSubtitle: resolveTicketingCMSStringOrDefault(
      formatMessage(loyaltyRecognitionMessages.loyaltyRecognitionSubtitle),
      content.payment.loyaltyRecognitionSubtitle
    ),
    loyaltyRecognitionHeading: resolveTicketingCMSStringOrDefault(
      formatMessage(loyaltyRecognitionMessages.loyaltyRecognitionHeading),
      content.payment.loyaltyRecognitionHeading
    ),
    loyaltyRecognitionLabel: resolveTicketingCMSStringOrDefault(
      formatMessage(loyaltyRecognitionMessages.loyaltyRecognitionLabel),
      content.payment.loyaltyRecognitionLabel
    ),
    loyaltyRecognitionPlaceHolder: resolveTicketingCMSStringOrDefault(
      formatMessage(loyaltyRecognitionMessages.loyaltyRecognitionPlaceHolder),
      content.payment.loyaltyRecognitionPlaceHolder
    ),
    loyaltyRecognitionErrorText: resolveTicketingCMSStringOrDefault(
      formatMessage(loyaltyRecognitionMessages.loyaltyRecognitionErrorText),
      content.payment.loyaltyRecognitionErrorText
    ),
    loyaltyRecognitionSuccessText: resolveTicketingCMSStringOrDefault(
      formatMessage(loyaltyRecognitionMessages.loyaltyRecognitionSuccessText),
      content.payment.loyaltyRecognitionSuccessText
    ),
    loyaltyRecognitionTicketCreditLabel: resolveTicketingCMSStringOrDefault(
      formatMessage(
        loyaltyRecognitionMessages.loyaltyRecognitionTicketCreditLabel
      ),
      content.payment.loyaltyRecognitionTicketCreditLabel
    ),
    loyaltyRecognitionTicketCreditsAvailableLabel:
      resolveTicketingCMSStringOrDefault(
        formatMessage(
          loyaltyRecognitionMessages.loyaltyRecognitionTicketCreditsAvailableLabel
        ),
        content.payment.loyaltyRecognitionTicketCreditsAvailableLabel
      ),
    loyaltyRecognitionTicketCreditsRichText: resolveTicketingCMSStringOrDefault(
      formatMessage(
        loyaltyRecognitionMessages.loyaltyRecognitionTicketCreditsRichText
      ),
      content.payment.loyaltyRecognitionTicketCreditsRichText
    ),
    closeButtonText: formatMessage(globalMessages.closeButtonText),
    submitButtonText: resolveTicketingCMSStringOrDefault(
      formatMessage(globalMessages.submitButtonText),
      content.payment.loyaltyRecognitionButtonText
    ),
    loyaltyNumberErrorRichText: resolveTicketingCMSStringOrDefault(
      formatMessage(loyaltyRecognitionMessages.loyaltyNumberErrorRichText),
      content.error.loyaltyNumberErrorRichText
    ),
  };
  return (
    <>
      <DazzlerWrapper step='payment' />
      <LegacyWrapper step='payment'>
        <div className='payment' data-testid='payment'>
          <ContainedRow>
            <h1 sx={{ textAlign: 'center' }}>
              {getStepNumberForDisplay(step)}
              {content.payment.title}
            </h1>
          </ContainedRow>

          <ContainedRow>
            {journeyTypeConfig.type === JOURNEY_TYPES.CONCESSIONS_ONLY ? (
              <ShowtimeHeroConcessions />
            ) : (
              <ShowtimeHeroLegacyWrapper />
            )}
          </ContainedRow>

          {selectedGiftCards.list.length > 0 && (
            <ContainedRow>
              <div className='giftcard-upsell-summary'>
                {selectedGiftCards.list.map((x, i) => (
                  <GiftCardUpsellSummaryRow
                    concession={x}
                    key={`${x.id}_${i}`}
                  />
                ))}
              </div>
            </ContainedRow>
          )}

          <CustomerDetailsCollapse
            isPageValidated={isPageValidated}
            onEditCustomer={resetHostedPayment}
          />

          {showNewsLetterSignUp && <GuestNewsletterSignUpCheckbox />}

          {shouldShowGuestMarketingOptions && (
            <GuestMarketing onEdit={resetHostedPayment} />
          )}

          <ContainedRow>
            <TermsAndConditions
              isPageValidated={isPageValidated}
              onEdit={resetHostedPayment}
            />
          </ContainedRow>

          {config.payment.showRoktWidget && <RoktWidget />}

          {!isReadyForPayment && (
            <ActionButton
              onClick={handleValidatePage}
              showIcon
              contained
              showWarningMessage={isPageValidated && !isReadyForPayment}
              warningMessage={content.payment.formErrorsMessage}
              warningTitle={content.payment.formErrorsSubTitle}
              hasMarginX
              variant='primary'
              showCartSummaryButtonOnMobile
            >
              {content.continueToPaymentText}
            </ActionButton>
          )}

          {isReadyForPayment && (
            <>
              {config.payment.enableGratuity && <Gratuity />}

              {config.payment.enableDonationField && !isIOSApp && (
                <Donations
                  donationConfig={donationConfig}
                  donationContent={donationContent}
                />
              )}

              {bookingData.isLoyaltyEarnPointsEnabled && (
                <RtsLoyaltyRecognition
                  loyaltyCardNumberEntryContent={loyaltyCardNumberEntryContent}
                  loyaltyCardNumberEntryConfig={loyaltyCardNumberEntryConfig}
                />
              )}

              <PaymentDesignWrapper
                giftcardEnabled={bookingData.isPaymentWithGiftCardEnabled}
                loyaltyPaymentEnabled={true}
                creditCardType={creditCardType}
                showPaymentForm={showPaymentForm}
                showGiftCardForm={showGiftCardForm}
                isPageValidated={isPageValidated}
                handleValidatePage={handleValidatePage}
                setPaymentFormVisibility={setPaymentFormVisibility}
                setGiftCardFormVisibility={setGiftCardFormVisibility}
                setCreditCardType={setCreditCardType}
              />

              {content.payment.refundPolicyRichText && (
                <ContainedRow styles={{ mt: 5 }}>
                  <Box className='info-container refund-policy' sx={{ p: 5 }}>
                    <h3 sx={{ textAlign: 'center' }}>
                      {content.payment.refundPolicyHeading}
                    </h3>
                    <RichText text={content.payment.refundPolicyRichText} />
                  </Box>
                </ContainedRow>
              )}
            </>
          )}
        </div>
      </LegacyWrapper>
    </>
  );
};

export default Payment;
