/** @jsxImportSource theme-ui */
import React from 'react';

import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import SplitPaymentDesign from './SplitPaymentDesign';
import TabbedFullPaymentDesign from './TabbedFullPaymentDesign';

import {
  LoyaltyRedemptionConfig,
  LoyaltyRedemptionContent,
} from '../../../../@types/modelTypes';
import {
  selectConfig,
  selectContent,
  selectGrandTotalAfterDiscountsInCents,
  selectGrandTotalWithoutDonationInCents,
  selectHasCardPaymentPromoTicketsInCart,
} from '../../../../store/Selectors';
import { resolveTicketingCMSStringOrDefault } from '../../../widgets/helpers';
import globalMessages from '../../../widgets/intl';
import messages from '../../../widgets/loyaltyredemption/intl';
import LoyaltyRedemption from '../../../widgets/loyaltyredemption/loyaltycomponents/LoyaltyRedemption';
import CardPaymentPromotionPaymentReminder from '../../../widgets/paymentform/common/CardPaymentPromotionPaymentReminder';
import ZeroBankPayment from '../../../widgets/paymentform/providers/ZeroBankPayment';
import ContainedRow from '../../layout/ContainedRow';
import RichText from '../../richtext/RichText';
interface Props {
  giftcardEnabled: boolean;
  loyaltyPaymentEnabled: boolean;
  creditCardType: string;
  showPaymentForm: boolean;
  showGiftCardForm: boolean;
  isPageValidated: boolean;
  handleValidatePage: () => void;
  setPaymentFormVisibility: (visibility: boolean) => void;
  setGiftCardFormVisibility?: (visibility: boolean) => void;
  setCreditCardType: (creditCardType: string) => void;
}

const PaymentDesignWrapper: React.FC<Props> = ({
  giftcardEnabled,
  loyaltyPaymentEnabled,
  creditCardType,
  showPaymentForm,
  showGiftCardForm,
  isPageValidated,
  handleValidatePage,
  setPaymentFormVisibility,
  setGiftCardFormVisibility,
  setCreditCardType,
}) => {
  const config = useSelector(selectConfig);
  const content = useSelector(selectContent);
  const grandTotalWithoutDonation = useSelector(
    selectGrandTotalWithoutDonationInCents
  );
  const grandTotal = useSelector(selectGrandTotalAfterDiscountsInCents);
  const hasCardPaymentPromotionTicketInCart = useSelector(
    selectHasCardPaymentPromoTicketsInCart
  );
  const { formatMessage } = useIntl();

  const useTabbedDesign =
    giftcardEnabled &&
    !config.currentCinema.enableSplitPayment &&
    !hasCardPaymentPromotionTicketInCart;

  const showLoyaltyRedemptionFullPayment =
    loyaltyPaymentEnabled &&
    config.currentCinema.enableLoyaltyRedemption &&
    !config.loyalty.allowSplitLoyaltyRedemption &&
    grandTotalWithoutDonation > 0;

  const loyaltyRedemptionConfig: LoyaltyRedemptionConfig = {
    enableCustomAmountLoyaltyRedemption:
      config.loyalty.enableCustomAmountLoyaltyRedemption,
  };

  const loyaltyRedemptionContent: LoyaltyRedemptionContent = {
    loyaltyRedemptionHeading: resolveTicketingCMSStringOrDefault(
      formatMessage(messages.loyaltyRedemptionHeading),
      content.payment.loyaltyRedemptionHeading
    ),
    loyaltyRedemptionRichText: resolveTicketingCMSStringOrDefault(
      formatMessage(messages.loyaltyRedemptionRichText),
      content.payment.loyaltyRedemptionRichText
    ),
    loyaltyRedemptionButtonText: resolveTicketingCMSStringOrDefault(
      formatMessage(messages.loyaltyRedemptionButtonText),
      content.payment.loyaltyRedemptionButtonText
    ),
    loyaltyRedemptionDollarsAppliedText: resolveTicketingCMSStringOrDefault(
      formatMessage(messages.loyaltyRedemptionDollarsAppliedText),
      content.payment.loyaltyRedemptionDollarsAppliedText
    ),
    loyaltyRedemptionNotEnoughBalanceText: resolveTicketingCMSStringOrDefault(
      formatMessage(messages.loyaltyRedemptionNotEnoughBalanceText),
      content.payment.loyaltyRedemptionNotEnoughBalanceText
    ),
    customLoyaltyRichText: resolveTicketingCMSStringOrDefault(
      formatMessage(messages.customLoyaltyRichText),
      content.payment.customLoyaltyRichText
    ),
    loyaltyRedemptionZeroBalanceHeading: resolveTicketingCMSStringOrDefault(
      formatMessage(messages.loyaltyRedemptionZeroBalanceHeading),
      content.payment.loyaltyRedemptionZeroBalanceHeading
    ),
    loyaltyRedemptionDollarsAppliedLabel: resolveTicketingCMSStringOrDefault(
      formatMessage(messages.loyaltyRedemptionDollarsAppliedLabel),
      content.payment.loyaltyRedemptionDollarsAppliedLabel
    ),
    loyaltyRedemptionRemainingBalanceRichText:
      resolveTicketingCMSStringOrDefault(
        formatMessage(messages.loyaltyRedemptionRemainingBalanceRichText),
        content.payment.loyaltyRedemptionRemainingBalanceRichText
      ),
    loyaltyRedemptionStillToPayRichText: resolveTicketingCMSStringOrDefault(
      formatMessage(messages.loyaltyRedemptionStillToPayRichText),
      content.payment.loyaltyRedemptionStillToPayRichText
    ),
    customLoyaltyValueToApplyLabel: resolveTicketingCMSStringOrDefault(
      formatMessage(messages.customLoyaltyValueToApplyLabel),
      content.payment.customLoyaltyValueToApplyLabel
    ),
    customLoyaltyValueInputPlaceholder: resolveTicketingCMSStringOrDefault(
      formatMessage(messages.customLoyaltyValueInputPlaceholder),
      content.payment.customLoyaltyValueInputPlaceholder
    ),
    customLoyaltyValueTooltipText: resolveTicketingCMSStringOrDefault(
      formatMessage(messages.customLoyaltyValueTooltipText),
      content.payment.customLoyaltyValueTooltipText
    ),
    removeButtonText: resolveTicketingCMSStringOrDefault(
      formatMessage(globalMessages.removeButtonText),
      content.payment.removeButtonText
    ),
    submitText: resolveTicketingCMSStringOrDefault(
      formatMessage(globalMessages.submitPaymentText),
      content.payment.submitText
    ),
  };
  return (
    <>
      <ContainedRow>
        <h2 sx={{ mt: 7, textAlign: 'center' }}>{content.payment.subTitle}</h2>
        <RichText text={content.payment.optionalPaymentRichText} />
      </ContainedRow>

      {grandTotal === 0 && (
        <ContainedRow>
          <ZeroBankPayment
            isPageValidated={isPageValidated}
            handleValidatePage={handleValidatePage}
          />
        </ContainedRow>
      )}

      {grandTotal > 0 && (
        <>
          {showLoyaltyRedemptionFullPayment && (
            <ContainedRow classNameWrapper='loyalty-redemption-wrapper'>
              <LoyaltyRedemption
                isPageValidated={isPageValidated}
                handleValidatePage={handleValidatePage}
                setPaymentFormVisibility={setPaymentFormVisibility}
                setGiftCardFormVisibility={setGiftCardFormVisibility}
                loyaltyRedemptionContent={loyaltyRedemptionContent}
              />
            </ContainedRow>
          )}

          {hasCardPaymentPromotionTicketInCart && (
            <CardPaymentPromotionPaymentReminder />
          )}

          {useTabbedDesign ? (
            <TabbedFullPaymentDesign
              creditCardType={creditCardType}
              showGiftCardForm={showGiftCardForm}
              isPageValidated={isPageValidated}
              handleValidatePage={handleValidatePage}
              setPaymentFormVisibility={setPaymentFormVisibility}
              setCreditCardType={setCreditCardType}
            />
          ) : (
            <SplitPaymentDesign
              creditCardType={creditCardType}
              showPaymentForm={showPaymentForm}
              showGiftCardForm={showGiftCardForm && giftcardEnabled}
              isPageValidated={isPageValidated}
              handleValidatePage={handleValidatePage}
              setPaymentFormVisibility={setPaymentFormVisibility}
              setGiftCardFormVisibility={setGiftCardFormVisibility}
              setCreditCardType={setCreditCardType}
              loyaltyRedemptionConfig={loyaltyRedemptionConfig}
              loyaltyRedemptionContent={loyaltyRedemptionContent}
            />
          )}
        </>
      )}
    </>
  );
};

export default PaymentDesignWrapper;
