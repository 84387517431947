import { defineMessages } from 'react-intl';

export default defineMessages({
  giftCardHeading: {
    id: 'gift-card-payment.gift-card-heading',
    defaultMessage: 'Gift card',
  },
  giftCardEnterRichText: {
    id: 'gift-card-payment.gift-card-enter-rich-text',
    defaultMessage: 'Enter your gift card number and PIN',
  },
  giftCardInputTooltipText: {
    id: 'gift-card-payment.gift-card-input-tooltip-text',
    defaultMessage: 'Enter your gift card number',
  },
  giftCardPlaceholder: {
    id: 'gift-card-payment.gift-card-placeholder',
    defaultMessage: 'Gift card number',
  },
  giftCardPinPlaceholder: {
    id: 'gift-card-payment.gift-card-pin-placeholder',
    defaultMessage: 'PIN',
  },
  giftCardExpiredText: {
    id: 'gift-card-payment.gift-card-expired-text',
    defaultMessage: 'This gift card has expired',
  },
  giftCardBalanceText: {
    id: 'gift-card-payment.gift-card-balance-text',
    defaultMessage: 'Balance:',
  },
  giftCardExpirationDateText: {
    id: 'gift-card-payment.gift-card-expiration-date-text',
    defaultMessage: 'Expiration date:',
  },
  giftCardPayButtonText: {
    id: 'gift-card-payment.gift-card-pay-button-text',
    defaultMessage: 'Apply gift card',
  },
  giftCardZeroBalanceText: {
    id: 'gift-card-payment.gift-card-zero-balance-text',
    defaultMessage: 'This gift card has a zero balance',
  },
  giftCardAppliedText: {
    id: 'gift-card-payment.gift-card-applied-text',
    defaultMessage: 'Gift card applied',
  },
  giftCardAppliedPartTwoText: {
    id: 'gift-card-payment.gift-card-applied-part-two-text',
    defaultMessage: 'to your order',
  },
  giftCardRemainingBalanceLabel: {
    id: 'gift-card-payment.gift-card-remaining-balance-label',
    defaultMessage: 'Remaining balance',
  },
  giftCardRemainingBalanceText: {
    id: 'gift-card-payment.gift-card-remaining-balance-text',
    defaultMessage: 'Remaining balance:',
  },
  giftCardPayRemainingBalanceText: {
    id: 'gift-card-payment.gift-card-pay-remaining-balance-text',
    defaultMessage: 'Pay remaining balance',
  },
  giftCardPayRemainingBalanceByCardText: {
    id: 'gift-card-payment.gift-card-pay-remaining-balance-by-card-text',
    defaultMessage: 'Pay remaining balance by card',
  },
  giftCardStillToPayLabel: {
    id: 'gift-card-payment.gift-card-still-to-pay-label',
    defaultMessage: 'Still to pay:',
  },
  giftCardAppliedLabel: {
    id: 'gift-card-payment.gift-card-applied-label',
    defaultMessage: 'Applied:',
  },
});
