import { defineMessages } from 'react-intl';

export default defineMessages({
  donationSubTitle: {
    id: 'donation.donation-sub-title-text',
    defaultMessage: 'Support our mission',
  },
  donationText: {
    id: 'donation.donation-text',
    defaultMessage: "I'd like to donate",
  },
  donationRoundUpRichText: {
    id: 'donation.donation-round-up-rich-text',
    defaultMessage: 'Round up by ##DonationRoundUpValue##',
  },
});
