import { defineMessages } from 'react-intl';

export default defineMessages({
  atLabel: {
    id: 'global.at-label',
    defaultMessage: 'At',
  },
  onLabel: {
    id: 'global.on-label',
    defaultMessage: 'On',
  },
  ofLabel: {
    id: 'global.of-label',
    defaultMessage: 'of',
  },
  selectedLabel: {
    id: 'global.selected-label',
    defaultMessage: 'selected',
  },
  includesLabel: {
    id: 'global.includes-label',
    defaultMessage: 'Includes',
  },
  yesButtonText: {
    id: 'global.yes-button-text',
    defaultMessage: 'Yes',
  },
  noButtonText: {
    id: 'global.no-button-text',
    defaultMessage: 'No',
  },
  cancelButtonText: {
    id: 'global.cancel-button-text',
    defaultMessage: 'Cancel',
  },
  selectButtonText: {
    id: 'global.select-button-text',
    defaultMessage: 'Select',
  },
  closeButtonText: {
    id: 'global.close-button-text',
    defaultMessage: 'Close',
  },
  removeButtonText: {
    id: 'global.remove-button-text',
    defaultMessage: 'Remove',
  },
  checkButtonText: {
    id: 'global.check-button-text',
    defaultMessage: 'Check',
  },
  confirmButtonText: {
    id: 'global.confirm-button-text',
    defaultMessage: 'Confirm',
  },
  submitButtonText: {
    id: 'global.submit-button-text',
    defaultMessage: 'Submit',
  },
  continueAsGuestButtonText: {
    id: 'global.continue-as-guest-button-text',
    defaultMessage: 'Continue as guest',
  },
  goBackToWebsiteButtonText: {
    id: 'global.go-back-to-website-button-text',
    defaultMessage: 'Go back to website',
  },
  fullNameLabel: {
    id: 'global.full-name-label',
    defaultMessage: 'Full name',
  },
  fullNamePlaceholder: {
    id: 'global.full-name-placeholder',
    defaultMessage: 'Full name',
  },
  fullNameValidationText: {
    id: 'global.full-name-validation-text',
    defaultMessage: 'Full name is invalid',
  },
  emailLabel: {
    id: 'global.email-label',
    defaultMessage: 'Email',
  },
  emailPlaceholder: {
    id: 'global.email-placeholder',
    defaultMessage: 'Email',
  },
  emailValidationText: {
    id: 'global.email-validation-text',
    defaultMessage: 'Email is invalid',
  },
  confirmEmailLabel: {
    id: 'global.confirm-email-label',
    defaultMessage: 'Confirm email',
  },
  confirmEmailPlaceholder: {
    id: 'global.confirm-email-placeholder',
    defaultMessage: 'Confirm email',
  },
  confirmEmailValidationText: {
    id: 'global.confirm-email-validation-text',
    defaultMessage: 'Email is invalid',
  },
  phoneLabel: {
    id: 'global.phone-label',
    defaultMessage: 'Phone',
  },
  phonePlaceholder: {
    id: 'global.phone-placeholder',
    defaultMessage: 'Phone',
  },
  phoneValidationText: {
    id: 'global.phone-validation-text',
    defaultMessage: 'Phone is invalid',
  },
  zipCodeLabel: {
    id: 'global.zip-code-label',
    defaultMessage: 'Zip code',
  },
  zipCodePlaceholder: {
    id: 'global.zip-code-placeholder',
    defaultMessage: 'Zip code',
  },
  zipCodeValidationText: {
    id: 'global.zip-code-validation-text',
    defaultMessage: 'Zip code is invalid',
  },
  formErrorsMessage: {
    id: 'global.form-errors-message-text',
    defaultMessage: 'Please complete all required fields',
  },
  formErrorsSubTitle: {
    id: 'global.form-errors-sub-title-text',
    defaultMessage: 'The following fields are required:',
  },
  submitPaymentText: {
    id: 'global.submit-payment-text',
    defaultMessage: 'Submit payment of',
  },
  amountSelectorCustomAmountLabel: {
    id: 'global.amount-selector-custom-amount-label',
    defaultMessage: 'Other amount',
  },
  amountSelectorSelectedText: {
    id: 'global.amount-selector-selected-text',
    defaultMessage: 'selected',
  },
  amountSelectorCustomAmountTooltipText: {
    id: 'global.amount-selector-custom-amount-tooltip-text',
    defaultMessage: 'The max. amount is',
  },
});
