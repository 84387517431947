import { defineMessages } from 'react-intl';

export default defineMessages({
  multiplePaymentProvidersCardLabel: {
    id: 'payment-form.multiple-payment-providers-card-label',
    defaultMessage: 'Card',
  },
  paymentLogosText: {
    id: 'payment-form.payment-logos-text',
    defaultMessage: 'We accept:',
  },
  submitText: {
    id: 'payment-form.submit-text',
    defaultMessage: 'SUBMIT PAYMENT OF',
  },
  stripePayButtonText: {
    id: 'payment-form.stripe-pay-button-text',
    defaultMessage: 'Pay',
  },
  cardNumberHelpText: {
    id: 'payment-form.card-number-help-text',
    defaultMessage: 'The long card number as printed on front of card',
  },
  cardNumberPlaceholder: {
    id: 'payment-form.card-number-placeholder',
    defaultMessage: 'Card number',
  },
  cardNumberLabel: {
    id: 'payment-form.card-number-label',
    defaultMessage: 'Card number',
  },
  cardNumberValidationText: {
    id: 'payment-form.card-number-validation-text',
    defaultMessage: 'Card number is required',
  },
  cardNumberInvalidText: {
    id: 'payment-form.card-number-invalid-text',
    defaultMessage: 'Card number is invalid',
  },
  nameOnCardHelpText: {
    id: 'payment-form.name-on-card-help-text',
    defaultMessage: 'Your full name as it appears on your card',
  },
  nameOnCardLabel: {
    id: 'payment-form.name-on-card-label',
    defaultMessage: 'Name on card',
  },
  nameOnCardPlaceholder: {
    id: 'payment-form.name-on-card-placeholder',
    defaultMessage: 'Full Name',
  },
  nameOnCardValidationText: {
    id: 'payment-form.name-on-card-validation-text',
    defaultMessage: 'Name on card is required',
  },
  expiryDateHelpText: {
    id: 'payment-form.expiry-date-help-text',
    defaultMessage: 'The card exp. month and year',
  },
  expiryDateLabel: {
    id: 'payment-form.expiry-date-label',
    defaultMessage: 'Exp. Date',
  },
  expiryMonthPlaceholder: {
    id: 'payment-form.expiry-month-placeholder',
    defaultMessage: 'Month',
  },
  expiryYearPlaceholder: {
    id: 'payment-form.expiry-year-placeholder',
    defaultMessage: 'Year',
  },
  expiryMonthValidationText: {
    id: 'payment-form.expiry-date-month-validation-text',
    defaultMessage: 'Exp. month is required',
  },
  expiryYearValidationText: {
    id: 'payment-form.expiry-date-year-validation-text',
    defaultMessage: 'Exp. year is required',
  },
  addressHelpText: {
    id: 'payment-form.address-help-text',
    defaultMessage: 'The first line of your billing address',
  },
  addressLabel: {
    id: 'payment-form.address-label',
    defaultMessage: 'Billing Address',
  },
  addressPlaceholder: {
    id: 'payment-form.address-placeholder',
    defaultMessage: 'First Line of Address',
  },
  addressValidationText: {
    id: 'payment-form.address-validation-text',
    defaultMessage: 'Billing address is required',
  },
  cvvHelpText: {
    id: 'payment-form.cvv-help-text',
    defaultMessage: 'The last few digits on the back of your card',
  },
  cvvLabel: {
    id: 'payment-form.cvv-label',
    defaultMessage: 'Security Number',
  },
  cvvPlaceholder: {
    id: 'payment-form.cvv-placeholder',
    defaultMessage: 'Security Number',
  },
  cvvValidationText: {
    id: 'payment-form.cvv-validation-text',
    defaultMessage: 'Security number is required',
  },
  cvvInvalidText: {
    id: 'payment-form.cvv-invalid-text',
    defaultMessage: 'Security number is invalid',
  },
  zipCodeHelpText: {
    id: 'payment-form.zip-code-help-text',
    defaultMessage: 'The zip code your card is registered to',
  },
  zipCodeLabel: {
    id: 'payment-form.zip-code-label',
    defaultMessage: 'Zip Code',
  },
  zipCodePlaceholder: {
    id: 'payment-form.zip-code-placeholder',
    defaultMessage: 'Zip Code',
  },
  zipCodeValidationText: {
    id: 'payment-form.zip-code-validation-text',
    defaultMessage: 'Zip code is required',
  },
  paymentDetailsSubtitle: {
    id: 'payment-form.payment-details-subtitle-text',
    defaultMessage: 'Complete payment form',
  },
  paymentInstructionsRichText: {
    id: 'payment-form.payment-instructions-rich-text',
    defaultMessage: `Just fill out the info below to finalize your transaction.`,
  },
});
