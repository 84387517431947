import { defineMessages } from 'react-intl';

export default defineMessages({
  loyaltyRedemptionHeading: {
    id: 'loyalty-redemption.loyalty-redemption-heading',
    defaultMessage: 'PAY WITH ##LoyaltyCreditApplied## LOYALTY CREDIT',
  },
  loyaltyRedemptionZeroBalanceHeading: {
    id: 'loyalty-redemption.loyalty-redemption-zero-balance-heading',
    defaultMessage: 'NO LOYALTY CREDIT AVAILABLE',
  },
  loyaltyRedemptionRichText: {
    id: 'loyalty-redemption.loyalty-redemption-rich-text',
    defaultMessage:
      '<p>YOU CURRENTLY HAVE ##LoyaltyPoints## POINTS WORTH: ##LoyaltyDollars##</p>',
  },
  loyaltyRedemptionButtonText: {
    id: 'loyalty-redemption.loyalty-redemption-button-text',
    defaultMessage: 'APPLY ##LoyaltyCreditApplied## LOYALTY CREDIT',
  },
  loyaltyRedemptionDollarsAppliedText: {
    id: 'loyalty-redemption.loyalty-redemption-dollars-applied-text',
    defaultMessage: 'Your loyalty points have been applied',
  },
  loyaltyRedemptionDollarsAppliedLabel: {
    id: 'loyalty-redemption.loyalty-redemption-dollars-applied-label',
    defaultMessage: 'LOYALTY POINTS APPLIED',
  },
  loyaltyRedemptionNotEnoughBalanceText: {
    id: 'loyalty-redemption.loyalty-redemption-not-enough-balance-text',
    defaultMessage:
      "You don't currently have enough Points to pay for this order. Please select an alternative method.",
  },
  loyaltyRedemptionRemainingBalanceRichText: {
    id: 'loyalty-redemption.loyalty-redemption-remaining-balance-rich-text',
    defaultMessage:
      '<p>You now have <strong>##LoyaltyDollarsRemaining##</strong> Loyalty Credit remaining.</p>',
  },
  loyaltyRedemptionStillToPayRichText: {
    id: 'loyalty-redemption.loyalty-redemption-still-to-pay-rich-text',
    defaultMessage:
      '<p>Please pay the remaining balance of <strong>##BalanceToPayAfterLoyaltyDollars##</strong> with another payment method.</p>',
  },
  customLoyaltyRichText: {
    id: 'loyalty-redemption.custom-loyalty-rich-text',
    defaultMessage:
      '<p>To use your Rewards Balance, enter the amount you wish to use. You can apply any amount up to the full value of your booking. You can pay any remaining balance using any other available payment method below.</p>',
  },
  customLoyaltyValueToApplyLabel: {
    id: 'loyalty-redemption.custom-loyalty-value-to-apply-label',
    defaultMessage: 'Value to apply',
  },
  customLoyaltyValueInputPlaceholder: {
    id: 'loyalty-redemption.custom-loyalty-value-input-placeholder',
    defaultMessage: 'Enter a custom amount',
  },
  customLoyaltyValueTooltipText: {
    id: 'loyalty-redemption.custom-loyalty-value-tooltip-text',
    defaultMessage: 'Loyalty credit cannot exceed',
  },
});
