import React, { memo } from 'react';

import Button from './button';
import Conditional from './conditional';
import Divider from './divider';
import Donation from './donation';
import FreeText from './freetext';
import GiftCardPayment from './giftcardpayment';
import Gratuity from './gratuity';
import Heading from './heading';
import Image from './image';
import Login from './login';
import LoyaltyCardNumberEntry from './loyaltycardnumberentry';
import LoyaltyRedemptionWidget from './loyaltyredemption';
import OrderDetails from './orderdetails';
import PaymentForm from './paymentform';
import PurchaserDetails from './purchaserdetails';
import RefundDetails from './refunddetails';
import SeatSelection from './seatselection';
import ShowtimeHeroWidget from './showtimehero';
import Spacer from './spacer';
import TheaterDetails from './theaterdetails';
import ThirdPartyPlaceholder from './thirdpartyplaceholder';
import ThirdPartyVoucherEntry from './thirdpartyvoucherentry';
import TicketSelection from './ticketselection';
import VoucherEntry from './voucherentry';

import { DazzlerWidgets } from '../../@types/dazzlerTypes';

type Props = {
  widget: DazzlerWidgets | null;
  step: string;
};

const Widget: React.FC<Props> = ({ widget, step }) => {
  switch (widget?.__typename) {
    case 'TicketingCMSJourneyFreeTextWidget':
      return <FreeText widget={widget} />;
    case 'TicketingCMSJourneyHeadingWidget':
      return <Heading widget={widget} />;
    case 'TicketingCMSJourneyImageWidget':
      return <Image widget={widget} />;
    case 'TicketingCMSJourneySpacerWidget':
      return <Spacer widget={widget} />;
    case 'TicketingCMSJourneyDividerWidget':
      return <Divider widget={widget} />;
    case 'TicketingCMSJourneyLoginWidget':
      return <Login widget={widget} />;
    case 'TicketingCMSJourneyShowtimeHeroWidget':
      return <ShowtimeHeroWidget widget={widget} step={step} />;
    case 'TicketingCMSJourneyButtonWidget':
      return <Button widget={widget} />;
    case 'TicketingCMSJourneyConditionalWidget':
      return <Conditional widget={widget} />;
    case 'TicketingCMSJourneySeatSelectionWidget':
      return <SeatSelection widget={widget} />;
    case 'TicketingCMSJourneyTicketSelectionWidget':
      return <TicketSelection widget={widget} />;
    case 'TicketingCMSJourneyVoucherEntryWidget':
      return <VoucherEntry widget={widget} />;
    case 'TicketingCMSJourneyThirdPartyVoucherEntryWidget':
      return <ThirdPartyVoucherEntry widget={widget} />;
    case 'TicketingCMSJourneyOrderDetailsWidget':
      return <OrderDetails widget={widget} />;
    case 'TicketingCMSJourneyPurchaserDetailsWidget':
      return <PurchaserDetails widget={widget} />;
    case 'TicketingCMSJourneyPaymentFormWidget':
      return <PaymentForm widget={widget} />;
    case 'TicketingCMSJourneyThirdPartyPlaceholderWidget':
      return <ThirdPartyPlaceholder widget={widget} />;
    case 'TicketingCMSJourneyGiftCardPaymentWidget':
      return <GiftCardPayment widget={widget} />;
    case 'TicketingCMSJourneyRefundDetailsWidget':
      return <RefundDetails widget={widget} />;
    case 'TicketingCMSJourneyLoyaltyRedemptionWidget':
      return <LoyaltyRedemptionWidget widget={widget} />;
    case 'TicketingCMSJourneyGratuityWidget':
      return <Gratuity widget={widget} />;
    case 'TicketingCMSJourneyDonationWidget':
      return <Donation widget={widget} />;
    case 'TicketingCMSJourneyTheaterDetailsWidget':
      return <TheaterDetails widget={widget} />;
    case 'TicketingCMSJourneyLoyaltyCardNumberEntryWidget':
      return <LoyaltyCardNumberEntry widget={widget} />;
    default:
      return <div>{widget?.__typename}</div>;
  }
};

export default memo(Widget);
