import React, { useState } from 'react';

import classNames from 'classnames';
import { Form } from 'react-bootstrap';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { Box } from 'theme-ui';

import messages from './intl';

import { QuantitySelectorContext } from '../../../@types/actionTypes';
import {
  TicketTypeModel,
  GroupedTicketTypes,
  NullableString,
} from '../../../@types/modelTypes';
import { CommonTrackingEvent } from '../../../@types/trackingTypes';
import { useAnalytics } from '../../../analytics/analyticsContext';
import { PEACH_CODES } from '../../../constants';
import { useValidateThirdPartyVoucherCode } from '../../../hooks/apiCalls/useValidateThirdPartyVoucherCode';
import { actionCreators } from '../../../store/ActionCreators';
import {
  selectConfig,
  selectDazzlerLocale,
  selectIsSeatsFirstJourney,
  selectJourneyTypeConfig,
  selectSelectedSeats,
  selectTicketTypes,
} from '../../../store/Selectors';
import ActionButton from '../../common/actionbutton/ActionButton';
import ActionButtonSpinner from '../../common/actionbuttonspinner/ActionButtonSpinner';
import ContainedRow from '../../common/layout/ContainedRow';
import VoucherTicketType from '../../common/tickets/VoucherTicketType';
import { resolveLocalisedStringOrDefault } from '../helpers';
import globalMessages from '../intl';
import BorderedCollapse from '../shared/borderedcollapse/BorderedCollapse';
import { WidgetData } from '../types';

const SUCESSFULLYAPPLIED = 'SUCESSFULLYAPPLIED';
const ALREADYAPPLIED = 'ALREADYAPPLIED';
const NOTRECOGNISED = 'NOTRECOGNISED';
const SERVERERROR = 'SERVERERROR';
const NOTICKETSRETURNED = 'NOTICKETSRETURNED';
const CARDSUSPENDED = 'CARDSUSPENDED';
const CARDEXPIRED = 'CARDEXPIRED';

type Props = {
  widget: WidgetData<'TicketingCMSJourneyThirdPartyVoucherEntryWidget'>;
  orderHasMaxTickets?: boolean;
};

const ThirdPartyVoucherEntry: React.FC<Props> = ({
  widget,
  orderHasMaxTickets,
}) => {
  const dispatch = useDispatch();

  const { formatMessage } = useIntl();
  const analytics = useAnalytics();

  const config = useSelector(selectConfig);

  const journeyTypeConfig = useSelector(selectJourneyTypeConfig);
  const locale = useSelector(selectDazzlerLocale);
  const ticketTypes = useSelector(selectTicketTypes);

  const [disableApplyButton, setDisableApplyButton] = useState(false);
  const [feedback, setFeedback] = useState<string | undefined>(undefined);
  const [showVoucherForm, setShowVoucherForm] = useState(
    ticketTypes
      ? ticketTypes.ticketTypeModels.some((t) => t.voucherCode)
      : false
  );
  const [voucherInput, setVoucherInput] = useState('');
  const selectedSeats = useSelector(selectSelectedSeats);
  const isSeatsFirstJourney = useSelector(selectIsSeatsFirstJourney);

  const thirdPartyMemberTicketsName =
    widget.shape?.thirdPartyVoucherEntryTicketName || null;

  const validTicketsName = (ticketThirdPartyName: NullableString) => {
    return (
      !widget.shape?.thirdPartyVoucherEntryTicketName ||
      ticketThirdPartyName === widget.shape?.thirdPartyVoucherEntryTicketName
    );
  };

  const validateThirdPartyVoucherCode = useValidateThirdPartyVoucherCode();

  const isSeatsFirst = journeyTypeConfig.isSeatsFirst;

  if (!ticketTypes) return null;

  const updateTicketTypes = (ticketTypeModels: TicketTypeModel[]) => {
    dispatch(actionCreators.setTicketTypes({ ticketTypeModels }));
  };

  const addVoucher = async (voucherCode: string) => {
    const response = await validateThirdPartyVoucherCode({
      voucherCode,
      thirdPartyTicketsName: thirdPartyMemberTicketsName,
    });

    if (response?.ok) {
      const responseContent = response.content;

      if (responseContent.peachCode === PEACH_CODES.noError) {
        const voucherTicketGroups: GroupedTicketTypes[] =
          response.content.groupedTicketTypes;
        const ticketTypeModels = ticketTypes.ticketTypeModels;

        voucherTicketGroups.forEach((gpt) => {
          gpt.ticketTypeModels.forEach((t) => {
            t.validatedVouchers = [voucherCode];
            t.voucherCode = voucherCode;
            t.voucherGroupMaxQuantity = response.content.quantityLimit;
            t.voucherDisplayName = `${t.displayName} (${voucherCode})`;
            t.isThirdPartyMemberVoucher = true;
            t.thirdPartyTicketsName = thirdPartyMemberTicketsName;
            ticketTypeModels.push(t);
          });
        });

        updateTicketTypes(ticketTypeModels);
        analytics?.track(CommonTrackingEvent.MEERKAT_CODE_VALIDATED);

        setFeedback(SUCESSFULLYAPPLIED);
        setVoucherInput('');
      } else if (
        responseContent.peachCode === PEACH_CODES.suspendedMemberCard
      ) {
        setFeedback(CARDSUSPENDED);
      } else if (responseContent.peachCode === PEACH_CODES.expiredMemberCard) {
        setFeedback(CARDEXPIRED);
      } else if (responseContent.peachCode === PEACH_CODES.noTicketReturn) {
        setFeedback(NOTICKETSRETURNED);
      } else {
        setFeedback(NOTRECOGNISED);
      }
    } else {
      setFeedback(SERVERERROR);
    }

    setDisableApplyButton(false);
  };

  const handleVoucherCheck = async () => {
    if (!voucherInput) return;
    setDisableApplyButton(true);
    const exists = ticketTypes.ticketTypeModels.some(
      (item) => item.voucherCode === voucherInput
    );
    if (!exists) {
      addVoucher(voucherInput);
    } else {
      setDisableApplyButton(false);
      setFeedback(ALREADYAPPLIED);
    }
  };

  const handleVoucherClick = (
    ticketTypeId: TicketTypeModel['id'],
    voucherCode: TicketTypeModel['voucherCode'],
    context: QuantitySelectorContext
  ) => {
    const { ticketTypeModels } = ticketTypes;
    const ticketTypeModel = ticketTypeModels.find(
      (x) => x.id === ticketTypeId && x.voucherCode === voucherCode
    );

    if (!ticketTypeModel) {
      return;
    }

    if (context === 'add') {
      ticketTypeModel.quantity += 1;
    } else {
      ticketTypeModel.quantity += -1;

      if (!isSeatsFirstJourney) {
        dispatch(actionCreators.removeAllSeats());
      }
    }

    updateTicketTypes(ticketTypeModels);
  };

  const hasTicketsWithTheSameVoucherAlreadyAdded = (
    voucherCode: TicketTypeModel['voucherCode'],
    ticketMaxQuantity: TicketTypeModel['maxQuantity'],
    voucherGroupMaxQuantity: TicketTypeModel['voucherGroupMaxQuantity']
  ) => {
    const ticketsWithTheSameVoucher = ticketTypes.ticketTypeModels.filter(
      (t) => t.voucherCode === voucherCode
    );

    if (!ticketsWithTheSameVoucher.length) {
      return false;
    }

    const ticketsWithTheSameVoucherSelectedQuantity =
      ticketsWithTheSameVoucher.reduce((a, b) => a + (b.quantity || 0), 0);

    return (
      ticketMaxQuantity === ticketsWithTheSameVoucherSelectedQuantity ||
      ticketsWithTheSameVoucherSelectedQuantity === voucherGroupMaxQuantity
    );
  };

  const shouldDisableAddTicket = (t: TicketTypeModel) => {
    const ticketsInArea = ticketTypes?.ticketTypeModels.filter(
      (ti: TicketTypeModel) => ti.areaCategoryCode === t.areaCategoryCode
    );

    const selectedTicketsInAreaCount =
      ticketsInArea?.reduce((a, b) => a + (b.quantity * b.nbSeats || 0), 0) ??
      0;

    const selectedSeatsInArea = selectedSeats?.filter(
      (s) => s.areaCategoryCode === t.areaCategoryCode
    );

    return (
      orderHasMaxTickets ||
      !!(t.maxQuantity && t.quantity === t.maxQuantity) ||
      hasTicketsWithTheSameVoucherAlreadyAdded(
        t.voucherCode,
        t.maxQuantity,
        t.voucherGroupMaxQuantity
      ) ||
      (isSeatsFirst &&
        selectedSeatsInArea.length === selectedTicketsInAreaCount) ||
      (isSeatsFirst &&
        selectedSeatsInArea.length < selectedTicketsInAreaCount + t.nbSeats)
    );
  };

  const shouldDisplayTicket = (t: TicketTypeModel) => {
    return (
      t.voucherCode &&
      t.isThirdPartyMemberVoucher &&
      (!isSeatsFirst ||
        selectedSeats.some((x) => x.areaCategoryCode === t.areaCategoryCode)) &&
      validTicketsName(t.thirdPartyTicketsName)
    );
  };

  const resolveFeedbackMessage = (feedbackType: string) => {
    if (feedbackType === ALREADYAPPLIED) {
      return resolveLocalisedStringOrDefault(
        formatMessage(messages.thirdPartyVoucherEntryAlreadyAppliedMessage),
        locale,
        widget.shape?.thirdPartyVoucherEntryAlreadyAppliedMessage
      );
    }
    if (feedbackType === CARDSUSPENDED) {
      return resolveLocalisedStringOrDefault(
        formatMessage(messages.thirdPartyVoucherEntryCardSuspendedMessage),
        locale,
        widget.shape?.thirdPartyVoucherEntryCardSuspendedMessage
      );
    }
    if (feedbackType === CARDEXPIRED) {
      return resolveLocalisedStringOrDefault(
        formatMessage(messages.thirdPartyVoucherEntryCardExpiredMessage),
        locale,
        widget.shape?.thirdPartyVoucherEntryCardExpiredMessage
      );
    }
    return resolveLocalisedStringOrDefault(
      formatMessage(messages.thirdPartyVoucherEntryErrorMessage),
      locale,
      widget.shape?.thirdPartyVoucherEntryErrorMessage
    );
  };

  return (
    <ContainedRow>
      <BorderedCollapse
        closeButtonText={formatMessage(globalMessages.closeButtonText)}
        heading={resolveLocalisedStringOrDefault(
          formatMessage(messages.thirdPartyVoucherEntryHeading),
          locale,
          widget.shape?.thirdPartyVoucherEntryHeading
        )}
        setShow={setShowVoucherForm}
        show={showVoucherForm}
      >
        <div
          className='voucher-container'
          data-testid='3rd-party-voucher-selector'
        >
          <div className='voucher-selector'>
            <Form>
              <Form.Label
                className={classNames(disableApplyButton && 'disabled')}
              >
                {resolveLocalisedStringOrDefault(
                  formatMessage(messages.thirdPartyVoucherEntryLabel),
                  locale,
                  widget.shape?.thirdPartyVoucherEntryLabel
                )}
              </Form.Label>
              <Box
                sx={{
                  justifyContent: 'space-between',
                  display: ['block', 'flex'],
                }}
              >
                <Box sx={{ pr: [0, 3], flexGrow: 1 }}>
                  <Form.Control
                    id='voucherNumber'
                    name='voucherNumber'
                    type='text'
                    placeholder={resolveLocalisedStringOrDefault(
                      formatMessage(messages.thirdPartyVoucherEntryPlaceholder),
                      locale,
                      widget.shape?.thirdPartyVoucherEntryPlaceholder
                    )}
                    onChange={(e) => {
                      setVoucherInput(e.target.value);
                    }}
                    value={voucherInput}
                    disabled={disableApplyButton}
                  />
                </Box>
                <Box sx={{ flexShrink: 0, mt: [4, 0] }}>
                  <ActionButton
                    onClick={handleVoucherCheck}
                    disabled={disableApplyButton}
                    variant='secondary'
                    mb={0}
                    mt={0}
                  >
                    {disableApplyButton ? (
                      <ActionButtonSpinner />
                    ) : (
                      formatMessage(globalMessages.checkButtonText)
                    )}
                  </ActionButton>
                </Box>
              </Box>
            </Form>
            {feedback && feedback !== SUCESSFULLYAPPLIED && (
              <Box className='warning-container' sx={{ mt: 5, p: 3 }}>
                <p>{resolveFeedbackMessage(feedback)}</p>
              </Box>
            )}
            <div className='voucher-list'>
              {ticketTypes?.ticketTypeModels
                ?.filter((t) => shouldDisplayTicket(t))
                ?.map((t) => (
                  <VoucherTicketType
                    key={`${t.id}${t.voucherCode}`}
                    ticket={t}
                    onClick={handleVoucherClick}
                    disableAdd={shouldDisableAddTicket(t)}
                    disableRemove={t.quantity === 0}
                    packageTicketsIncludesLabel={`${formatMessage(
                      globalMessages.includesLabel
                    )}:`}
                    hideTax={config.currentCinema.hideTax}
                  />
                ))}
            </div>
          </div>
        </div>
      </BorderedCollapse>
    </ContainedRow>
  );
};

export default ThirdPartyVoucherEntry;
