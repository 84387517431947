/* eslint-disable react/no-unknown-property */
/** @jsxImportSource theme-ui */
import React from 'react';

import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { useScreenWidth } from '../../../contextProviders/screenWidthContext';
import {
  displayPrice,
  getTotalPriceToDisplay,
} from '../../../services/Helpers';
import {
  selectConfirmationData,
  selectContentCartSummary,
  selectCurrencyConfig,
  selectGrandTotalAfterDiscountsInCents,
  selectJourneyTypeConfig,
  selectRefundTotalInCents,
  selectSelectedSeasonPass,
  selectStep,
} from '../../../store/Selectors';

const CartSummaryTotal: React.FC = () => {
  const { pathname } = useLocation();
  const { isLargeScreenWidth } = useScreenWidth();

  const confirmationData = useSelector(selectConfirmationData);
  const contentCartSummary = useSelector(selectContentCartSummary);
  const currencyConfig = useSelector(selectCurrencyConfig);
  const grandTotalWithDiscount = useSelector(
    selectGrandTotalAfterDiscountsInCents
  );
  const journeyTypeConfig = useSelector(selectJourneyTypeConfig);
  const refundTotalInCents = useSelector(selectRefundTotalInCents);
  const selectedSeasonPass = useSelector(selectSelectedSeasonPass);
  const step = useSelector(selectStep);

  const hideTotal = journeyTypeConfig.isSeatsFirst && step < 2;
  const isConfirmation = pathname.includes('/confirmation/');
  const isRefund = pathname.includes('/refund/');

  if (hideTotal) return null;

  const confirmationTotalPriceToDisplay =
    confirmationData &&
    displayPrice(confirmationData.totalPrice, currencyConfig);

  const totalPriceToDisplay = getTotalPriceToDisplay(
    isRefund,
    refundTotalInCents,
    grandTotalWithDiscount,
    hideTotal,
    journeyTypeConfig,
    selectedSeasonPass,
    currencyConfig
  );

  return (
    <div
      className='cart-summary-total cart-summary-row cart-summary-row-flex bottom-border'
      data-testid='cs-total'
      sx={{
        background: 'sidebarBackground',
        bottom: 0,
        position: isLargeScreenWidth ? 'relative' : 'sticky',
      }}
    >
      <span className='h1' style={{ textTransform: 'uppercase' }}>
        {contentCartSummary.totalLabel}
      </span>
      <span className='h1'>
        {isConfirmation ? confirmationTotalPriceToDisplay : totalPriceToDisplay}
      </span>
    </div>
  );
};

export default CartSummaryTotal;
